export const diccionario = [
    { servicio: "landing", method: "get", path: "/automotriz/landing" },
    { servicio: "modelos", method: "get", path: "/datos/modelos/marca" },
    { servicio: "ubicaciones", method: "get", path: "/datos/ubicaciones" },
    { servicio: "registrar", method: "post", path: "/usuario/registrar" },
    { servicio: "registrar-confirmar", method: "post", path: "/usuario/registrar/confirmar" },
    { servicio: "login", method: "post", path: "/usuario/login" },
    { servicio: "recuperar", method: "post", path: "/usuario/recuperar" },
    { servicio: "recuperar-confirmar", method: "post", path: "/usuario/recuperar/confirmar" },
    { servicio: "buscar", method: "post", path: "/automotriz/buscar" },
    { servicio: "perfil", method: "get", path: "/usuario/perfil" },
    { servicio: "perfil-guardar", method: "post", path: "/usuario/perfil" },
    { servicio: "publicacion", method: "get", path: "/automotriz/publicacion" },
    { servicio: "mis-vehiculos", method: "get", path: "/automotriz/misvehiculos" },
    { servicio: "vehiculo", method: "get", path: "/automotriz/vehiculo" },
    { servicio: "editar", method: "get", path: "/automotriz/misvehiculos/editar" },
    { servicio: "guardar", method: "post", path: "/automotriz/misvehiculos/editar/vehiculo" },
    { servicio: "eliminar-vehiculo", method: "delete", path: "/automotriz/misvehiculos/editar/vehiculo" },
    { servicio: "caracteristicas", method: "post", path: "/automotriz/misvehiculos/editar/caracteristicas" },
    { servicio: "publicar", method: "post", path: "/automotriz/misvehiculos/editar/publicacion" },
    { servicio: "imagenes-subir", method: "post", path: "/automotriz/misvehiculos/imagenes/subir" },
    { servicio: "imagenes-borrar", method: "delete", path: "/automotriz/misvehiculos/imagenes/eliminar" },
    { servicio: "mantenciones", method: "get", path: "/automotriz/misvehiculos/editar/mantenciones"},
    { servicio: "mantencion-editar", method: "get", path: "/automotriz/misvehiculos/editar/mantenciones"},
    { servicio: "mantencion-guardar", method: "post", path: "/automotriz/misvehiculos/editar/mantenciones"},
    { servicio: "enviar-mensaje", method: "post", path: "/automotriz/mensaje/enviar"},
]
   