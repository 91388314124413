<template>
    <div>
        <v-col class="pr-5 pl-5">
    
            <Header>
                <img slot="logo" src="../../assets/images/fdc/Logo.png" >
            </Header>
            <br><br><br>
            <v-row v-if="resultado == 'error'">
                <v-col cols="12">
                    <h3 style="margin-bottom: 90%; color: red">{{ resultado_mensaje }}</h3>
                </v-col>
            </v-row>
            <v-row v-if="publicacion && resultado == 'ok'">
            
                <v-col cols="12"  sm="12" md="12"  lg="8" xl="8">
                    <v-row>
                        <v-col cols="12">
                            <v-row>
                                <div class="pl-5  text-h5 text-sm-h4 text-md-h3" v-if="publicacion.publicacion">{{publicacion.publicacion.titulo}}</div>
                            </v-row>
                        </v-col>
                       
                        <v-col cols="12"  sm="12" md="8"  lg="7" xl="7" class="pt-5 pb-5">
                              
    
                            <v-card class="mb-5">
                                <v-card-title>
                                    Fotografías
                                </v-card-title>
                                <Carousel
                                :speed=10000
                                autoplay
                                :items-per-view=1
                                :autoplay-timeout=5000
                                
                                >
                                <v-img v-for="foto in publicacion.fotografias" :key="foto.url" :src="foto.url"></v-img>
                                </Carousel>
                                <v-card-title>
                                    Publicación
                                </v-card-title >
                                <v-card-text>
                                <v-row v-if="publicacion.publicacion">
                                    <v-col v-for="linea in publicacion.publicacion.lineas" :key="linea.label" cols="6" class="m-0 p-0">
                                       
                                        <span class="textofuerte">{{linea.label}}:</span>
                                         &nbsp; 
                                        <span>{{linea.text}}</span>
                                            
                                       
                                    </v-col>
                                </v-row>
                                </v-card-text>
                                <v-card-title>
                                    Características
                                </v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col v-for="linea in publicacion.caracteristicas" :key="linea.label" cols="6" class="m-0 p-0">
                                        
                                            <span class="textofuerte">{{linea.label}}:</span>
                                            &nbsp; 
                                            <span>{{linea.text}}</span>
                                                
                                        
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                               
                                <v-card-title>
                                    Detalles
                                </v-card-title>
                                <v-card-text class="m-0 p-0">
                                    <v-row class="m-0 p-0 loscheck">
                                    <v-col v-for="linea in publicacion.equipamiento" :key="linea.label" cols="6" class="m-0 p-0">
                                     
                                            <v-checkbox class="m-0 p-0"  v-model="linea.checked" readonly :label="linea.label"></v-checkbox>
                                          
                                       
                                       
                                    </v-col>
                                </v-row>
                                </v-card-text>
                                <div v-if="publicacion.mantenciones.length > 0">
                                    <v-card-title>
                                        Mantenciones
                                    </v-card-title>
                                    <v-timeline
                                        align-top
                                        dense
                                    >
                                        <v-timeline-item
                                        color="primary"
                                        small
                                        v-for="mantencion in publicacion.mantenciones" :key="mantencion.comentario"
                                        >
                                        <v-row class="pt-1">
                                            <v-col >
                                                <strong>
                                                    {{devolverFecha(mantencion.fecha)}}
                                                    <span v-if="mantencion.kilometraje"> &nbsp; | &nbsp; {{ Number(mantencion.kilometraje).toLocaleString() }} km</span>
                                                </strong>
                                                <div class="text-caption">
                                                    <div style="width: 100%">
                                                        {{mantencion.comentario}}
                                                    </div>
                                                    <div style="width: 100%">
                                                        <a v-if="mantencion.url_factura" :href="mantencion.url_factura" target="_blank">Factura</a>
                                                        <a v-if="mantencion.url_ot" :href="mantencion.url_ot" target="_blank" style="margin-left: 10px;">OT</a>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        </v-timeline-item>
                                    </v-timeline>
                                    <br>
                               </div>
                                 
    
                            </v-card>
                        </v-col>
                        <v-col  sm="12" md="4"  lg="5" xl="5" class="pt-5 pb-5">
                            <v-card >
                                 <v-row class="pt-5">
                                    <v-col cols="6" class="text-center">
    
                                         <!--  <ShareNetwork
                                                network="facebook"
                                                url="https://pc3.fabricadecodigo.dev/automotriz/publicacion/a2760ea2-0cc1-11ed-89d7-ed50c4e73b72" 
                                                title="Awesome Article"
                                                description="asdadsadasdsa"
                                                
                                                >
                                               <v-icon >mdi-facebook </v-icon>
                                            </ShareNetwork>  -->
                                             
                                         <v-icon @click="compartirFacebook()">mdi-facebook </v-icon>        
                                       
                                      
                                    </v-col>
                                     <v-col cols="6" class="text-center">
                                       
                                             <v-icon @click="compartirTwitter()">mdi-twitter </v-icon>           
                                           
                                      
                                    </v-col>
                                </v-row>
                                <v-card-text>
                                    <v-tabs>
                                        <v-tab> Datos del dueño </v-tab>
                                        <v-tab-item class="pt-5">
                                            <v-text-field label="Nombre" v-model="publicacion.usuario.nombre" readonly> </v-text-field>
                                            <v-text-field label="Correo electrónico" v-model="publicacion.usuario.email" readonly> </v-text-field>
                                            <v-text-field label="Teléfono fijo" v-model="publicacion.usuario.telefonofijo" readonly> </v-text-field>
                                            <v-text-field label="Celular" v-model="publicacion.usuario.telefonomovil" readonly> </v-text-field>
                                            <v-text-field label="Ubicación" v-model="publicacion.usuario.ubicacion" readonly> </v-text-field>
                                            <v-row class="pt-5 pb-5" >
                                                <v-col v-if="publicacion.usuario.telefonomovil" >
                                                    <v-btn target="_blank" :href="'https://wa.me/' + publicacion.usuario.telefonomovil " class="green  mx-2" fab  >  
                                                        <v-icon color="white">mdi-whatsapp</v-icon>
                                                    </v-btn>
                                                </v-col>
                                                <v-col v-if="publicacion.usuario.telefonomovil">
                                                    <v-btn :href="'tel:' +  publicacion.usuario.telefonomovil" class="purple mx-2" fab  >  
                                                        <v-icon color="white" >mdi-phone</v-icon>
                                                    </v-btn>
                                                </v-col>
                                                <v-col v-if="publicacion.usuario.email" >
                                                    <v-btn :href="'mailto:'+ publicacion.usuario.email" class="blue mx-2" fab  >  
                                                        <v-icon color="white">mdi-email</v-icon>
                                                    </v-btn>
                                                </v-col>                                         
                                            </v-row>
                                        </v-tab-item>
                                        <v-tab> Enviar mensaje </v-tab>
                                        <v-tab-item class="pt-5">
                                            <v-text-field label="Nombre completo" v-model="mensaje.nombre"> </v-text-field>
                                            <v-text-field label="Correo electrónico" v-model="mensaje.email"></v-text-field>
                                            <v-text-field label="Teléfono" v-model="mensaje.telefono"> </v-text-field>
                                            <v-row>
                                                <v-col cols="6">
                                                <v-autocomplete
                                                    :items="itemsRegiones"
                                                    label="Región"
                                                    item-text="nombre"
                                                    item-value="idregion"
                                                    v-model="regionSeleccionada"
                                                    @change="cargarCiudades()"
                                                ></v-autocomplete>
                                                </v-col>
                                                <v-col  cols="6">
                                                <v-autocomplete
                                                    :items="itemsCiudades"
                                                    label="Ciudad"
                                                    item-text="nombre"
                                                    item-value="idciudad"
                                                ></v-autocomplete>
                                                </v-col>
                                            </v-row>
                                            <v-textarea
                                                outlined
                                                name="input-7-4"
                                                label="Comentarios"
                                                v-model="mensaje.mensaje"
                                            ></v-textarea>
                                            <v-btn block class="primary" @click="enviarMensaje()">
                                                Enviar
                                            </v-btn>
                                            <v-alert closable type="warning" v-if="msg_response.resultado != null && msg_response.resultado == 'error'" style="margin-top: 20px">{{ msg_response.mensaje }}</v-alert>
                                            <v-alert closable type="success" v-if="msg_response.resultado != null && msg_response.resultado == 'ok'" style="margin-top: 20px">{{ msg_response.mensaje }}</v-alert>
                                        </v-tab-item>
                                    </v-tabs>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                   
                </v-col>
                <v-col sm="12" md="12"  lg="4" xl="4">
                    <Publicidad :publicidad="publicidad" v-if="mostrarPublicidad"></Publicidad>
    
                </v-col>
    
                
            </v-row>
            
        </v-col>
        <Footer></Footer>
      </div>
        
      
    </template>
    
    <script>
    import { fdcService } from '../../fdc/dispatcher'
    import Header from "../../components/header/HeaderThree";
    import Publicidad from "../../components/fdc/Publicidad";
    import Carousel from "vue2-simple-carousel";
    import VueSocialSharing from 'vue-social-sharing'
    import Vue from 'vue';
    import Footer from "../../components/footer/FooterTwo";
    import moment from 'moment';
    Vue.use(VueSocialSharing, {
      networks: {
        fakeblock: 'https://fakeblock.com/share?url=@url&title=@title'
      }
    })
    
    export default {
        components:{
            Header,
            Publicidad,
            Carousel,
            VueSocialSharing,
            Footer
        },
        data(){
            return {
                publicacion: null,
                publicidad: null,
                mostrarPublicidad: false,
                itemsRegiones: null,
                itemsCiudades: null,
                todasLasCiudades: null,
                regionSeleccionada: null,
                resultado: null, resultado_mensaje: null,
                mensaje: {
                    nombre: null,
                    email: null,
                    telefono: null,
                    idregion: null,
                    idciudad: null,
                    mensaje: null,
                    idpublicacion: this.$route.params.id
                },
                msg_response: {
                    resultado: null,
                    mensaje: null
                },
            }
        },
        head: {
            title: function() {
                return { inner: "Precio conversable ", separator: null, complement: "Automotriz" };
            },
            meta: function() {
                return [
                    { name: "application-name", content: "cl.fabricadecodigo.guiasvirtuales", id: "fdc_app"},
                    { name: "description", content: "Si tiene un vehículo, registrelo en Precio Conversable - Portal Automotriz", id: "fdc_description" },
                // { property: "article:publisher", content: this.facebookSitio, id: "fdc_publisher" },
                    { property: "og:locale", content: "es_ES", id: "fdc_og:locale"},
                    { property: "og:type", content: "website", id: "fdc_og:type"},
                    { property: "og:title", content: this.tituloSitio, id: "fdc_og:title" },
                    { property: "og:url", content: this.url , id: "fdc_og:url" },
                    { property: "og:site_name", content: "Precio conversable", id: "fdc_og:site_name"},
                    { property: "og:description", content: "Precio conversable - Automotriz", id: "fdc_og:description" },
                    { property: "og:image", content: this.fotografia, id: "fdc_og:image" },
                    { property: "og:image:type", content: "image/png", id: "fdc_og:image:type" },
                    { property: "og:image:alt", content: "Precio conversable - Automotriz", id: "fdc_og:image:alt" },
                    //{ property: "og:image:secure_url", content: fdc.getWeb("/imagenes/Logo_Guias_Virtuales.png"), id: "fdc_og:image:secure_url" },
                    { property: "og:image:url", content: this.fotografia, id: "fdc_og:image:url" },
                    { property: "twitter:card", content:"Precio conversable - Automotriz", id: "fdc_twitter:card"},
                    { property: "twitter:url", content: this.url, id: "fdc_twitter:url"},
                    { property: "twitter:title", content: this.tituloSitio, id: "fdc_twitter:title"},
                    { property: "twitter:image", content:  this.fotografia, id: "fdc_twitter:image" },
                    { property: "generator", content: "Fábrica de código", id: "fdc_generator" },
                ]
            },
        },
    
        
        /* metainfo() {
            return { 
                meta:[
                    { name: 'description', content: "AJsdkakda"},
                    { charset: '8859' },
                    { property: 'og:title', content: this.publicacion.publicacion.titulo , id:'idtitle'},
                    { property: 'og:site_name', content: 'Precioconversalbe' , id:'idsite_name'},
                    { property: 'og:description', content: "asdadadasdsadad" , id:'iddescription'},
                    { property: 'og:type', content: 'page' , id:'idtype'},
                    { property: 'og:url', content: "https://pc3.fabricadecodigo.dev/automotriz/publicacion/a2760ea2-0cc1-11ed-89d7-ed50c4e73b72" , id:'idurl'},
                    { property: 'og:image', content: "https://www.google.com/imgres?imgurl=https%3A%2F%2Feus-www.sway-cdn.com%2Fs%2FdVIDutDci5Ro3F22%2Fimages%2FGaq9vLV-4IyxW8%3Fquality%3D1080%26isThumbnail%3DTrue&imgrefurl=https%3A%2F%2Fsway.office.com%2FdVIDutDci5Ro3F22%3Fref%3DLink&tbnid=z8nLAEPVBLtCpM&vet=12ahUKEwjyqIehxpb6AhXLOLkGHR8pDJYQMygAegUIARCwAQ..i&docid=Ji2jvoaePU0_QM&w=1080&h=547&itg=1&q=aasd&ved=2ahUKEwjyqIehxpb6AhXLOLkGHR8pDJYQMygAegUIARCwAQ" , id:'idimage'}    
                ]
            }
        }, */
        
      
        created(){
        this.cargarPublicacion()
        this.cargarPublicidad()
        },
        computed: {
            tituloSitio() {
                if (this.publicacion) {
                    return this.publicacion.publicacion.titulo;
                }
                return "Publicacion";
            },
             fotografia() {
                if (this.publicacion) {
                    return this.publicacion.fotografias[0].url;
                }
                return "Publicacion";
            },
             url() {
               
                return location.href;
            },
        },  
        methods:{
            cargarPublicidad(){
                fdcService.execute("landing").then( r => {
                    this.publicidad = r.publicidad
                    this.mostrarPublicidad = true
                })
            },
             abrirDocumento(url){
                window.open(url, "_blank")
            },
            cargarPublicacion(){
                fdcService.execute("publicacion", [this.$route.params.id]).then(r => {
                    this.resultado = r.resultado
                    this.resultado_mensaje = r.mensaje
                    this.publicacion = r
                    this.itemsRegiones = r.msg_regiones
                    this.todasLasCiudades = r.msg_ciudades
                    setTimeout(() => { this.$emit("updateHead")}, 300)
                })
            },
             cargarCiudades(){
                this.itemsCiudades = this.todasLasCiudades.filter( ciudad =>ciudad.idregion == this.regionSeleccionada)
            },
            compartirFacebook(){
                const texto = "Mira esta publicación en Precio conversable - Portal Automotriz!"
                const url = location.href;
                const navUrl = "https://www.facebook.com/share.php?u=" + url +"&t=" + texto
                window.open(navUrl , '_blank');
            },
            compartirTwitter() {
                const texto = "Mira esta publicación en Precio conversable - Portal Automotriz!"
                const url = "https://pc3.fabricadecodigo.dev" + this.$route.path;
               // const imagen = "https://www.petbull.cl/web/image/product.template/1371/image_256/Pedigree%20Adulto%20Raza%20Peque%C3%B1as%2021kg?unique=5caf370"
                const navUrl = "http://twitter.com/share?card=photo&text=" + texto +"&url=" + url          
                window.open(navUrl, '_blank');
            },
            devolverFecha(fecha){
                return moment(fecha).format("DD-MM-YYYY")
            },
            enviarMensaje() {
                fdcService.execute("enviar-mensaje", this.mensaje).then(response => {
                    if (response.resultado == "ok") {
                        this.msg_response = {
                            resultado: "ok",
                            mensaje: "Mensaje enviado correctamente"
                        }
                    } else {
                        this.msg_response = {
                            resultado: "error",
                            mensaje: response.mensaje
                        }
                    }
                    setTimeout(() => {
                        this.msg_response = {
                            resultado: "",
                            mensaje: ""
                        }
                    }, 5000)
                }).catch(error => {
                    this.msg_response = {
                        resultado: "error",
                        mensaje: "Error al enviar el mensaje"
                    }
                    setTimeout(() => {
                        this.msg_response = {
                            resultado: "",
                            mensaje: ""
                        }
                    }, 5000)
                })
            },
            
    
        }
    }
    </script>
    
    <style>
    .textofuerte{
        font-weight: bold;
    }
    .carousel__button span {
        font-size: 3em;
        background-color: #ffff;
    }
    
    .loscheck .v-messages {
      
       height: 0%!important;
       width: 0%!important;
       min-height: 0%!important;
    
    }
    .loscheck .v-input-control {
      padding: 0px 0px!important;
      margin: 0px 0px 0px 0px!important;
       
    
    }
    
    .loscheck .v-input--selection-controls {
         margin-top: 0px; 
        padding-top: 0px; 
    }
    
    </style>