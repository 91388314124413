<template>
    
  
    <v-dialog
      
      max-width="600px"
      v-model="show"
    >
      <v-card >
        <v-card-title>
          <span class="text-h5">PrecioConversable - Características</span>
        </v-card-title>
        
            <v-container class=" pt-10  pb-10 ">
              <v-row>
                <v-col cols="6">
                    <v-autocomplete
                        :items="itemsColor"
                        label="Color"
                        item-text="descripcion"
                        item-value="idsubtipo"
                        v-model="guardarCaracteristicas.caracteristicas.idcolor"
                        @change="traerModelos()"    
                    >
                    </v-autocomplete>
                </v-col>
                <v-col  cols="6">
                    <v-autocomplete
                        :items="itemsTransmision"
                        label="Transmision"
                        item-text="descripcion"
                        item-value="idsubtipo"
                        v-model="guardarCaracteristicas.caracteristicas.idtransmision"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col  cols="6">
                    <v-autocomplete
                       :items="itemsPuertas"
                        label="Puertas"
                        item-text="descripcion"
                        item-value="idsubtipo"
                        v-model="guardarCaracteristicas.caracteristicas.idpuertas"
                    >
                    </v-autocomplete>
                </v-col>
                 <v-col  cols="6">
                    <v-autocomplete
                       :items="itemsCombustible"
                        label="Combustible"
                        item-text="descripcion"
                        item-value="idsubtipo"
                        v-model="guardarCaracteristicas.caracteristicas.idcombustible"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col  cols="6">
                    <v-autocomplete
                       :items="itemsDireccion"
                        label="Direccion"
                        item-text="descripcion"
                        item-value="idsubtipo"
                        v-model="guardarCaracteristicas.caracteristicas.iddireccion"
                    >
                    </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-card-title>
                    Detalles
                </v-card-title>
                <v-card-text class="m-0 p-0">
                    <v-row class="m-0 p-0 loscheck">
                    <v-col v-for="linea in guardarCaracteristicas.equipamiento" :key="linea.label" cols="6" class="m-0 p-0">
                            <v-checkbox class="m-0 p-0"  v-model="linea.checked"  :label="linea.descripcion"></v-checkbox>
                    </v-col>
                </v-row>
                </v-card-text>
              </v-row>

               
              <v-col cols="12">
                <v-btn class="blue red-text" block @click="guardar()">
                    Guardar
                </v-btn>
              </v-col> 
              <v-col cols="12" class="mt-2">
                <v-btn class="red red-text" block @click="show = false">
                    Cerrar
                </v-btn>
              </v-col> 
                
               
            
                
                
                    
           
            
          </v-container>
          

      </v-card>

      <v-dialog v-model="dialogExito" width="500" >
        <v-card >
        <v-card-text class="text-h6 grey lighten-2">
          Caracteristicas guardadas correctamente
        </v-card-text>

        

      

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogExito = false , show = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>

      <v-dialog v-model="dialogFracaso" width="500" >
        <v-card >
        <v-card-title class="text-h5 grey lighten-2">
          Error al guardar
        </v-card-title>

        <v-card-text class="notificacion">
          Verifique los datos.
        </v-card-text>

      

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogFracaso = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>
     

    </v-dialog>
  
</template>


<script>
import { fdcService } from '../../fdc/dispatcher'
import {api} from '../../fdc/api'
export default {


    props: ["value","vehiculoEditar",],
    computed: {
    show: {
        get () {
        return this.value
        },
        set (value) {
         this.$emit('input', value)
      }
    },
   
   
  }, data(){
        return{
            dialogExito: false,
            dialogFracaso: false,
            mensajeError: false,
            mensajeExito: false,
            itemsColor: [],
            itemsTransmision: [],
            itemsPuertas: [],
            itemsCombustible: [],
            itemsDireccion: [],
            guardarCaracteristicas: {
                id: null,
                equipamiento: [],
                caracteristicas: {
                    idcolor: 0,
                    idcombustible: 0,
                    iddireccion: 0,
                    idpuertas: 0,
                    idtransmision: 0,
                }
            },
           

        }

    },
     created(){
        this.cargarInformacion();
        
    },
    

    methods: {
        cargarInformacion(){
          
            fdcService.execute('editar', [this.vehiculoEditar]).then( r => {
              this.guardarCaracteristicas.caracteristicas = r.caracteristicas
              this.itemsColor = r.colores
              this.itemsTransmision = r.transmisiones
              this.itemsPuertas = r.puertas
              this.itemsCombustible = r.combustibles
              this.itemsDireccion = r.direcciones
              this.guardarCaracteristicas.equipamiento = r.equipamiento
              this.guardarCaracteristicas.id = r.vehiculo.id
              
            })
        },
        cargarCiudades(){
            this.itemsCiudades = this.todasLasCiudades.filter( ciudad =>ciudad.idregion == this.regionSeleccionada)
        },

        
         traerModelos(idmarca = null){
          if (idmarca == null){
            fdcService.execute("modelos", [this.vehiculo.idmarca]).then( r => {
                this.itemsMV = r.modelos
            })
          }else{
            fdcService.execute("modelos", [idmarca]).then( r => {
                this.itemsMV = r.modelos
             })
          }
             
        },
        guardar(){
            fdcService.execute("caracteristicas", this.guardarCaracteristicas).then( r => {
               if (r.resultado == "ok"){
               this.dialogFracaso = false
                this.dialogExito = true
              } 
              else {
                this.dialogFracaso = true
                this.dialogExito = false
              }
            }).catch(error => {
              this.dialogFracaso = true
              this.dialogExito = false
            })
        } 
    }
}
</script>

<style>
.notificacion {
  font-size: 1.2em;
  color:black !important;
}
</style>