<template>
    
  
    <v-dialog
      v-model="show"
      max-width="1000"
    >
      <v-card >
        <v-card-title>
          <span class="text-h5">PrecioConversable - Mantenciones</span>
        </v-card-title>
        
        <v-container class=" pt-10  pb-10 pl-10 pr-10">
                <v-row>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="mantencion.fechamantencion"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="mantencion.fechamantencion"
                            label="Fecha de la mantencion"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="mantencion.fechamantencion"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="menu = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(mantencion.fechamantencion)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                      <v-text-field
                      label="Kilometraje"
                      type="number"
                      counter="8"
                      v-model.number="mantencion.kilometraje"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                      <v-textarea
                      label="Comentarios"
                      v-model="mantencion.comentario"
                      outlined
                     
                      >
                      </v-textarea>
                    </v-col>
                    

                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-row v-if="mantencion.url_factura">
                          <v-col class="pl-5 pr-5">
                            <a  class="blue--text text-h5"   :href="mantencion.url_factura" target="blank">Subido: Factura </a>
                            <v-icon class="red--text" right @click="borrarFactura">mdi-close-circle</v-icon>
                                    
                          </v-col>
                        </v-row>
                         <v-row v-else>
                          <v-col class="pl-5 pr-5">
                            <a  class="red--text text-h5">Factura NO subido </a>                
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                             <vue-dropzone
                                ref="factura"
                                id="customdropzone"
                                :options="dropzoneOptionsFactura"
                                @vdropzone-complete="afterCompleteFactura"
                              ></vue-dropzone>
                          </v-col>
                        </v-row>
                    
                       
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                         <v-row v-if="mantencion.url_ot">
                          <v-col class="pl-5 pr-5">
                              <a  class="blue--text text-h5" :href="mantencion.url_ot" target="blank">Subido: Orden de trabajo </a>
                              <v-icon  class="red--text" right @click="borrarOt">mdi-close-circle</v-icon>
                          </v-col>
                        </v-row>
                        <v-row v-else>
                          <v-col class="pl-5 pr-5">
                            <a  class="red--text text-h5">Orden de trabajo NO subido </a>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                             <vue-dropzone
                            ref="ot"
                            id="customdropzone"
                            :options="dropzoneOptionsOT"
                            @vdropzone-complete="afterCompleteOt"
                          ></vue-dropzone>
                          </v-col>
                        </v-row>
                        
                      </v-col>
                      <v-col cols="12">
                         <v-row class="pt-5">
                          <v-col >
                            <v-btn class="blue mr-5" @click="guardar()">
                              Guardar
                            </v-btn>
                            <v-btn color="secondary" @click="show = false">
                                  Volver
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                     
                    </v-row>
                   
                    
                    
                </v-row>
        </v-container>
          

      </v-card>
      
      <v-dialog v-model="dialogExito" width="500" >
        <v-card >
        <v-card-text class="text-h5 black--text text-center pt-5">
          Mantención guardada correctamente
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogExito = false , show = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>

      <v-dialog v-model="dialogFracaso" width="500" >
        <v-card >
        <v-card-title class="text-h5 grey lighten-2">
          Error al guardar
        </v-card-title>

        <v-card-text class="notificacion">
          Verifique los datos.
        </v-card-text>

      

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogFracaso = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>
     

    </v-dialog>
  
</template>


<script>
import { fdcService } from '../../fdc/dispatcher'
import {api} from '../../fdc/api'
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import axios from 'axios';
import moment from 'moment';
export default {


    props: ["value","id", "vehiculo", "vehiculoInt"],
    components: {
      vueDropzone: vue2Dropzone
    },
    computed: {
    show: {
        get () {
        return this.value
        },
        set (value) {
         this.$emit('input', value)
      }
    },
   
   
  }, 

  watch: {
    id(newId, oldId){
      if (newId !== oldId){
        this.cargarInformacion()
      } 
    }
  },
  data(){
        return{
            dropzoneOptionsFactura: {
              url: "https://httpbin.org/post",
              thumbnailWidth: 150,
              maxFiles: 1,
              thumbnailHeight: 150,
              addRemoveLinks: false,
              acceptedFiles: ".jpg, .pdf",
              dictDefaultMessage: `<p class='text-default'><i class='fa fa-cloud-upload mr-2'></i> Arrastrar el archivo de la factura o hacer click para subir</p>
                <p class="form-text"> Tipos aceptados: .jpg, .pdf</p>
                `
            },
            dropzoneOptionsOT: {
              url: "https://httpbin.org/post",
              thumbnailWidth: 150,
              thumbnailHeight: 150,
              addRemoveLinks: false,
              acceptedFiles: ".jpg, .pdf",
              dictDefaultMessage: `<p class='text-default'><i class='fa fa-cloud-upload mr-2'></i> Arrastrar el archivo de la orden de trabajo o hacer click para subir</p>
                <p class="form-text"> Tipos aceptados: .jpg, .pdf</p>
                `
            },
            dialogExito: false,
            dialogFracaso: false,
            mensajeError: false,
            menu: false,
            mantencion: {
              idmantencion: null,
              idvehiculo: this.vehiculoInt,
              fecharegistro: "2019-08-24",
              fechamantencion: moment().format("YYYY-MM-D"),
              kilometraje: 0,
              comentario: "",
              factura: null,
              ot: null,
              url_factura: "",
              url_ot: ""
            },
            nombreFactura: null,
            nombreOt: null
        }

    },
     created(){
        this.cargarInformacion();  
    },
    

    methods: {
        cargarInformacion(){
            
            if (this.vehiculo !== null && this.id !== null){
              fdcService.execute('mantencion-editar', [this.vehiculo, this.id]).then( r => {
               this.mantencion = r.mantencion
              })
            }
            
        },
        cargarCiudades(){
            this.itemsCiudades = this.todasLasCiudades.filter( ciudad => ciudad.idregion == this.regionSeleccionada)
        },
        
        guardar(){
          let mantencionGuardar = {idvehiculo: this.vehiculo, mantencion: this.mantencion}
          fdcService.execute('mantencion-guardar', mantencionGuardar).then( r => {
            if(r.resultado == "ok"){
              if(this.id !== null){
                this.dialogExito = true
                this.dialogFracaso = false
              }
              
              this.$emit("recargar")
              if(this.id == null) this.show = false;
              
            }
            else {
              this.dialogExito = false
              this.dialogFracaso = true
            }
          }).catch(error => {
              this.dialogExito = false
              this.dialogFracaso = true
          })
        },
        afterCompleteFactura(upload){
          this.isLoading = true;
          let formData = new FormData()
          const _file = upload
          formData.append('files', _file)
          
          if(!this.mantencion.factura){
            axios({
              method: "post",
              url: api.getHost("/automotriz/mantenciones/subir/") +  this.vehiculo,
              data: formData,
              headers: { "Content-Type": "multipart/form-data", "Authorization": "Bearer " + api.getUsuario().token },
            }).then( r => {
                this.mantencion.factura = r.data.documento
                this.mantencion.url_factura = r.data.url
                this.nombreFactura = upload.name
                console.log("asdsa",r)
                this.$refs.factura.removeAllFiles()
            })
          }else {
            this.$refs.factura.removeAllFiles()
          }
          
        },
        afterCompleteOt(upload){
          this.isLoading = true;
          let formData = new FormData()
          const _file = upload
          formData.append('files', _file)

          if(!this.mantencion.ot){
            axios({
              method: "post",
              url: api.getHost("/automotriz/mantenciones/subir/") +  this.vehiculo,
              data: formData,
              headers: { "Content-Type": "multipart/form-data", "Authorization": "Bearer " + api.getUsuario().token },
            }).then( r => {
                this.mantencion.ot = r.data.documento
                 this.mantencion.url_ot = r.data.url
                this.nombreOt = upload.name
                this.$refs.ot.removeAllFiles()
            })
          }else{
            this.$refs.ot.removeAllFiles()
          } 

          
        },

        borrarFactura(){
          this.mantencion.factura = ""
          
        },
        borrarOt(){
          this.mantencion.ot = ""
          
        }
        
    }
}
</script>

<style>
.notificacion {
  font-size: 1.2em;
  color:black !important;
}

.v-date-picker-table  .v-btn__content ,
.v-picker__actions  .v-btn__content ,
.v-card__actions  .v-btn__content 
{
  color: black !important;
}
</style>