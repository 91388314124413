<template>
    
  
    <v-dialog
      v-model="show"
      max-width="1000"
    >
      <v-card >
        <v-card-title>
          <span class="text-h5">PrecioConversable - Mantenciones</span>
        </v-card-title>
        
            <v-container  class=" pt-10  pb-10 ">
                <v-row >
                   
                    <v-col cols="3">
                        <v-btn class="blue" large @click="mantencionEditar = null, openMantenciones = true">
                            Nueva mantencion
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="justify-center pt-10 pb-10" v-if="mantenciones.length > 0 ">
                    <v-col  cols="12" sm="12" md="12" lg="6" xl="6"  class=" mb-10" v-for="mantencion in mantenciones" :key="mantencion.idmantencion" >
                    <v-card>
                        <v-card-title class="grey lighten-4 pl-10 pr-10" >
                            {{mantencion.kilometraje + " Kms"  }}
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col class="justify-center mb-5" cols="12">
                                    <p class="text-center  black--text text-h6">{{"Registrado el " + mantencion.fecharegistro}}</p>
                                </v-col>
                                 <v-col  cols="6" class="pl-10 pr-10 mt-2" >
                                    <v-row>
                                        <v-col cols="12">
                                            <p class="font-weight-bold black--text text-h6">Fecha</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p  class="font-weight-black black--text ">{{ mantencion.fecharegistro}}</p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                 <v-col  cols="6" class="pl-10 pr-10 mt-2">
                                     <v-row >
                                        <v-col cols="12" >
                                            <p class=" black--text font-weight-bold text-h6">{{"Kilometraje "}}</p>
                                        </v-col>
                                        <v-col cols="12" > 
                                            <p class=" black--text font-weight-black ">{{mantencion.kilometraje + " Kms"}}</p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="pr-10 pl-10 pt-10 justify-center">
                                <v-col cols="6" class="justify-center">
                                    <v-btn class="blue" block @click="mantencionEditar = mantencion.idmantencion, openMantenciones = true">
                                        <v-icon left class="white--text" > mdi-square-edit-outline</v-icon>
                                        Editar
                                       
                                    </v-btn>
                                </v-col>
                              
                            </v-row>
                        </v-card-text>
                    </v-card>
                    
                    </v-col>
                </v-row>
                 <v-row v-else class="justify-center pt-10 pb-10">
                    <v-col cols="12" >
                        <h3 class="justify-center"> No hay mantenciones para mostrar</h3>
                    </v-col>
                </v-row>
          </v-container>

         <EditarMantenciones @recargar="recargar" v-model="openMantenciones" v-if="openMantenciones" :id="mantencionEditar" :vehiculo="vehiculo" :vehiculoInt="vehiculoInt"></EditarMantenciones>
          

      </v-card>
      
      <v-dialog v-model="dialogExito" width="500" >
        <v-card >
        <v-card-text class="text-h6 grey lighten-2">
          Vehículo guardado correctamente
        </v-card-text>

        

      

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogExito = false , show = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>

      <v-dialog v-model="dialogFracaso" width="500" >
        <v-card >
        <v-card-title class="text-h5 grey lighten-2">
          Error al guardar
        </v-card-title>

        <v-card-text class="notificacion">
          Verifique los datos.
        </v-card-text>

      

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogFracaso = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>
     

    </v-dialog>
  
</template>


<script>
import { fdcService } from '../../fdc/dispatcher'
import {api} from '../../fdc/api'
import EditarMantenciones from '../fdc/EditarMantencion.vue'
export default {

    components:{
      EditarMantenciones
    },

    props: ["value","id", "vehiculoInt"],
    computed: {
    show: {
        get () {
        return this.value
        },
        set (value) {
         this.$emit('input', value)
      }
    },
   
   
  }, 

  watch: {
    id(newId, oldId){
      if (newId !== oldId){
        this.cargarInformacion()
      } 
    }
  },
  data(){
        return{
            dialogExito: false,
            dialogFracaso: false,
            mensajeError: false,
            mantenciones: [],
            mantencionEditar: null,
            openMantenciones: false,
            vehiculo: this.id
            
        }

    },
     created(){
        this.cargarInformacion();  
    },
    

    methods: {
        cargarInformacion(){
            let idvehiculo = this.id
            if (this.id == null){
                idvehiculo = 0
            } 
            fdcService.execute('mantenciones', [idvehiculo]).then( r => {
                this.mantenciones = r.mantenciones
            })
        },
        recargar(){
          console.log("afaasasdasdasdassd")
          this.cargarInformacion()
        }
       

        
        
         
    }
}
</script>

<style>
.notificacion {
  font-size: 1.2em;
  
}

.v-btn__content  {
    color:white!important;
}
</style>