<template>
    <div>
    <Header>
            <img slot="logo" src="../../assets/images/fdc/Logo.png" >
    </Header>

    <br><br><br><br><br><br><br><br><br>
    <v-row v-responsive="['hidden-all','lg','xl']">
        <v-col></v-col>
        <v-col>
            <v-card v-if="confirmacionExitosa">
                <v-card-text>
                    <v-card-title>
                        Tu registro se ha confirmado con exito.
                    </v-card-title>

                    <v-card-text>
                        Ya puedes usar mas funcionalidades del sitio.
                    </v-card-text>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                       to="/"
                    >
                        Volver al inicio
                    </v-btn>
                </v-card-actions>
            </v-card>
             <v-card v-else>
                <v-card-text>
                    <v-card-title>
                        No se pudo confirmar.
                    </v-card-title>

                    <v-card-text>
                        Ha habido un problema al intentar confirmar tu cuenta, intentalo denuevo. Si el problema persiste, por favor contacta a soporte.
                    </v-card-text>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                       to="/"
                    >
                        Volver al inicio
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
        <v-col></v-col>
    </v-row>

    <v-row v-responsive="['hidden-all','xs', 's','md']">
       
        <v-col>
            <v-card v-if="confirmacionExitosa">
                <v-card-text>
                    <v-card-title>
                        Tu registro se ha confirmado con exito.
                    </v-card-title>

                    <v-card-text>
                        Ya puedes usar mas funcionalidades del sitio.
                    </v-card-text>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                       to="/"
                    >
                        Volver al inicio
                    </v-btn>
                </v-card-actions>
            </v-card>
             <v-card v-else>
                <v-card-text>
                    <v-card-title>
                        No se pudo confirmar.
                    </v-card-title>

                    <v-card-text>
                        Ha habido un problema al intentar confirmar tu cuenta, intentalo denuevo. Si el problema persiste, por favor contacta a soporte.
                    </v-card-text>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                       to="/"
                    >
                        Volver al inicio
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
        
    </v-row>
    
        
    <v-footer  app bottom fixed padless >
        <Footer class="footer"></Footer>
    </v-footer>
    </div>
       
</template>

<script>
import Header from "../../components/header/HeaderThree";
import Footer from "../../components/footer/FooterTwo";
import { fdcService } from '../../fdc/dispatcher';

import Vue from 'vue';


export default {
     components:{
        Header,
        Footer,
    },
    data(){
        return {
            confirmacion: {
                email: this.$route.params.email,
                codigo: this.$route.params.token
            },
            confirmacionExitosa: null
        }
    },
    mounted(){
        this.confirmarRegistro();
    },
    methods:{
        confirmarRegistro(){
            fdcService.execute("registrar-confirmar", this.confirmacion).then( r => {
                if (r.resultado == "ok") {
                    this.confirmacionExitosa = true
                }else {
                    this.confirmacionExitosa = false
                }
            }).catch( error => {
                 this.confirmacionExitosa = false
            })
        }
    }

}
</script>

<style>
.footer{
    width: 100%;
}


</style>