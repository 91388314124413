<template>
    
  
    <div>
            <v-row class=" pt-10  pb-10 ">
                <v-col cols="12"  sm="6" md="6"  lg="5" xl="4">
                    <v-autocomplete
                        :items="itemsMarcas"
                        label="Marca"
                        item-text="nombre"
                        item-value="idmarca"
                        v-model="vehiculo.idmarca"
                        @change="traerModelos()"    
                    >
                    </v-autocomplete>
                </v-col>
                <v-col   cols="12"  sm="6" md="6"  lg="5" xl="4">
                    <v-autocomplete
                        :items="itemsMV"
                        label="Modelo"
                        item-text="nombre"
                        item-value="idmodelo"
                        v-model="vehiculo.idmodelo"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col   cols="12"  sm="6" md="6"  lg="5" xl="4">
                    <v-autocomplete
                        :items="itemsAnos"
                        label="Año"
                        v-model="vehiculo.ano"
                    >
                    </v-autocomplete>
                </v-col>
               
            
                <v-col
                    cols="12"  sm="6" md="6"  lg="5" xl="4"
                >
                    <v-text-field
                        label="Cilindrada (en miles, ej: 1.6 -> 1600)"
                        required
                        v-model="vehiculo.cilindrada"
                    >
                    </v-text-field>
                </v-col>
                <v-col  cols="12"  sm="6" md="6"  lg="5" xl="4">
                    <v-text-field
                    label="Kilometraje"
                    v-model="vehiculo.kilometraje"
                    >
                    </v-text-field>
                </v-col>
                <v-col  cols="12"  sm="6" md="6"  lg="5" xl="4">
                    <v-text-field
                        label="Dueños anteriores"
                        v-model="vehiculo.duenosanteriores"
                        required
                    >
                    </v-text-field>
                </v-col>
                <v-col  cols="12"  sm="6" md="6"  lg="5" xl="4">
                    <v-text-field
                        label="Placa patente"
                        v-model="vehiculo.placa"
                        required
                    >
                    </v-text-field>
                </v-col> 
                <v-col  cols="12"  sm="6" md="6"  lg="5" xl="4">
                    <v-autocomplete
                        :items="visibilidadesPatente"
                        label="Mostrar patente"
                        item-text="text"
                        item-value="value"
                        v-model="vehiculo.mostrarplaca"
                    >
                       
                    </v-autocomplete>
                </v-col> 
                

                <v-col cols="12">
                  <v-row class="justify-center mt-5">
                     <v-col  cols="12"  sm="8" md="6"  lg="4" xl="4" class="mt-1">
                        <v-btn class="red " block @click="salir()">
                            Cerrar
                        </v-btn>
                    </v-col> 
                     <v-col  cols="12"  sm="8" md="6"  lg="4" xl="4" class="mt-1">
                        <v-btn class="blue red-text" block @click="guardarVehiculo()">
                            Siguiente
                        </v-btn>
                    </v-col> 
                    
                  </v-row>
                </v-col>
 
          </v-row>
          

      
      
      <v-dialog v-model="dialogExito" width="500" >
        <v-card >
        <v-card-text class="text-h6 grey lighten-2">
          Vehículo guardado correctamente
        </v-card-text>

        

      

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogExito = false , show = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>

      <v-dialog v-model="dialogFracaso" width="500" >
        <v-card >
        <v-card-title class="text-h5 grey lighten-2">
          Error al guardar
        </v-card-title>

        <v-card-text class="notificacion">
          Verifique los datos.
        </v-card-text>

      

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogFracaso = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>
     

    </div>
  
</template>


<script>
import { fdcService } from '../../fdc/dispatcher'
import {api} from '../../fdc/api'
export default {


    props: ["value","id",],
    computed: {
    show: {
        get () {
        return this.value
        },
        set (value) {
         this.$emit('input', value)
      }
    },
   
   
  }, 
  watch: {
    id(newId, oldId){
      if (newId !== oldId){
        this.cargarInformacion()
      }
      if (newId == null){
        this.vehiculo = {
          idvehiculo: 0,
          idmarca: 0,
          idmodelo: 0,
          idautomotora: 0,
          ano: 0,
          cilindrada: 0,
          kilometraje: 0,
          duenosanteriores: 0,
          placa: null,
          mostrarplaca: 0,
          id: null
        }
      }
    }
  },
  data(){
        return{
           


            dialogExito: false,
            dialogFracaso: false,
            mensajeError: false,
            mensajeExito: false,
            itemsMarcas: [],
            itemsAnos: [],
            itemsMV: [],
            visibilidadesPatente:[
                {
                    text: "Mostrar publicamente",
                    value: 1
                },
                 {
                    text: "No mostrar",
                    value: 0
                }
            ],
            vehiculo: {
                idvehiculo: 0,
                idmarca: 0,
                idmodelo: 0,
                idautomotora: 0,
                ano: 0,
                cilindrada: 0,
                kilometraje: 0,
                duenosanteriores: 0,
                placa: null,
                mostrarplaca: 0,
                id: null
            }

        }

    },
    created(){
        this.cargarInformacion();  
    },
    

    methods: {
        cargarInformacion(){
          
          let idvehiculo = this.id
          if (this.id == null){
            idvehiculo = 0
          } 
          fdcService.execute('editar', [idvehiculo]).then( r => {
            if(idvehiculo != 0)  this.vehiculo = r.vehiculo
            if(idvehiculo == 0)  this.vehiculo.id = "nuevo";
            this.itemsMarcas = r.marcas
            this.itemsAnos = r.anos
            if(idvehiculo != 0) this.traerModelos(r.vehiculo.idmarca)
            
          })
        },
        cargarCiudades(){
            this.itemsCiudades = this.todasLasCiudades.filter( ciudad =>ciudad.idregion == this.regionSeleccionada)
        },

        
         traerModelos(idmarca = null){
          if (idmarca == null){
            fdcService.execute("modelos", [this.vehiculo.idmarca]).then( r => {
                this.itemsMV = r.modelos
            })
          }else{
            fdcService.execute("modelos", [idmarca]).then( r => {
                this.itemsMV = r.modelos
             })
          }
             
        },
        guardarVehiculo(){
           
            
            fdcService.execute("guardar", this.vehiculo).then( r => {
              if (r.resultado == "ok"){
                
                this.$emit('accion', {id: r.codigo})
              } 
              else {
                this.dialogFracaso = true
                this.dialogExito = false
              }
            }).catch(error => {
              this.dialogFracaso = true
              this.dialogExito = false
            })
        },
        salir(){
          this.$emit("cerrarPaso")
        }
    }
}
</script>

<style>
.notificacion {
  font-size: 1.2em;
  color:black !important;
}
</style>