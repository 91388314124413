<template>
    
  
    <v-dialog
      persistent
      max-width="600px"
      v-model="show"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Registrarse</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
               
              >
                <v-text-field
                  label="Nombre*"
                  required
                  
                  
                  v-model="formularioRegistro.nombre"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="6">
                  <v-text-field
                    label="Primer apellido*"
                    v-model="formularioRegistro.apellidopaterno"
                  >
                  </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Segundo apellido*"
                      v-model="formularioRegistro.apellidomaterno"
                      required
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
               
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Correo electrónico*"
                   v-model="formularioRegistro.email"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Contraseña*"
                      type="password"
                      v-model="formularioRegistro.password"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Confirmar contraseña*"
                      type="password"
                      v-model="formularioRegistro.confirmacion"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                
             
                
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                
              >
              <v-row>
                <v-col cols="6">
                   <v-autocomplete
                    :items="itemsRegiones"
                    label="Región"
                    item-text="nombre"
                    item-value="idregion"
                    v-model="regionSeleccionada"
                    @change="cargarCiudades()"
                    
                  ></v-autocomplete>
                </v-col>
                <v-col  cols="6">
                  <v-autocomplete
                    :items="itemsCiudades"
                    label="Ciudad"
                    item-text="nombre"
                    item-value="idciudad"
                    v-model="formularioRegistro.idciudad"
                    
                  ></v-autocomplete>
                </v-col>
              </v-row>
               
                 
              </v-col>
            </v-row>
          </v-container>
          
        </v-card-text>
        <v-card-actions>
           <v-btn
            color="blue darken-1"
            text
            @click="$emit('tieneCuenta'), show = false"
          >
            Tengo cuenta
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="show = false"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="registrarUsuario()"
          >
            Registrar
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-dialog v-model="dialogExito" width="500" >
        <v-card >
        <v-card-title class="text-h5 grey lighten-2">
          Registro exitoso
        </v-card-title>

        <v-card-text class="notificacion">
          Se le ha enviado un link de confirmacion a su correo electrónico para finalizar el proceso.
        </v-card-text>

      

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogExito = false , show = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>

      <v-dialog v-model="dialogFracaso" width="500" >
        <v-card >
        <v-card-title class="text-h5 grey lighten-2">
          Error en el registro
        </v-card-title>

        <v-card-text  v-if="mensajeError" class="notificacion">
          {{mensajeError}}
        </v-card-text>
        <v-card-text v-else class="notificacion">
          Verifique los datos.
        </v-card-text>

      

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogFracaso = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>
     

    </v-dialog>
  
</template>


<script>
import { fdcService } from '../../fdc/dispatcher'
export default {


    props: {
     value: Boolean
    },
    computed: {
    show: {
        get () {
        return this.value
        },
        set (value) {
         this.$emit('input', value)
      }
    },
   
   
  }, data(){
        return{
            dialogExito: false,
            dialogFracaso: false,
            mensajeError: false,
            mensajeExito: false,
            itemsRegiones: [],
            itemsCiudades: [],
            todasLasCiudades: [],
            regionSeleccionada: null,
            formularioRegistro: {
              email: null,
              nombre: null,
              apellidopaterno: null,
              apellidomaterno: null,
              idciudad: null,
              password: null,
              confirmacion: null
            }

        }

    },
     created(){
        this.cargarUbicaciones();
    },
    

    methods: {
        cargarUbicaciones(){
            fdcService.execute('ubicaciones').then( r => {
                this.itemsRegiones = r.regiones
                this.todasLasCiudades = r.ciudades
                
            })
        },
        cargarCiudades(){
            this.itemsCiudades = this.todasLasCiudades.filter( ciudad =>ciudad.idregion == this.regionSeleccionada)
        },

        registrarUsuario(){
          fdcService.execute("registrar", this.formularioRegistro).then( r => {
            
           
            if (r.resultado == "ok"){
               this.dialogExito = true;
               this.mensajeError = null
             
            }else {
                this.dialogFracaso = true;
                this.mensajeError = r.mensaje
            }
          }).catch( error => {
             this.dialogFracaso = true;
             if(r.mensaje) this.mensajeError = r.mensaje
             else this.mensajeError = false
          })

          
        }
    }
}
</script>

<style>
.notificacion {
  font-size: 1.2em;
  color:black !important;
}
</style>