<template>
    
  
    <v-dialog
      
      max-width="600px"
      v-model="show"
    >
      <v-card >
        <v-card-title>
          <span class="text-h5">PrecioConversable - Imagenes</span>
        </v-card-title>

        <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
        
        <v-container class=" pt-10  pb-10 ">
             <v-row>
                <v-col v-for="imagen in imagenes" :key="imagen.idimagen" cols="6" class="mt-5 mb-5">
                    <v-card>
                        <v-icon class="red--text">mdi-close-circle</v-icon>
                        <v-img :aspect-ratio="16/9" contain src="../../assets/images/fdc/auto.jpg"></v-img>
                        <v-text-field label="Descripcion" v-model="imagen.descripcion">

                        </v-text-field>
                    </v-card>

                </v-col>
             </v-row>
            
        </v-container>
          

      </v-card>

      <v-dialog v-model="dialogExito" width="500" >
        <v-card >
        <v-card-text class="text-h6 grey lighten-2">
          Imagenes guardadas correctamente
        </v-card-text>

        

      

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogExito = false , show = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>

      <v-dialog v-model="dialogFracaso" width="500" >
        <v-card >
        <v-card-title class="text-h5 grey lighten-2">
          Error al guardar
        </v-card-title>

        <v-card-text class="notificacion">
          Verifique los datos.
        </v-card-text>

      

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogFracaso = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>
     
    
    </v-dialog>
  
</template>


<script>
import { fdcService } from '../../fdc/dispatcher'
import {api} from '../../fdc/api'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
export default {

    components: {
        vueDropzone: vue2Dropzone
    },
    props: ["value","vehiculoEditar",],
    computed: {
    show: {
        get () {
        return this.value
        },
        set (value) {
         this.$emit('input', value)
      }
    },
   
   
  }, data(){
        return{
            dialogExito: false,
            dialogFracaso: false,
            mensajeError: false,
            mensajeExito: false,
            imagenes: [],
            dropzoneOptions: {
                url: 'https://httpbin.org/post',
                thumbnailWidth: 150,
                maxFilesize: 1.5,
                headers: { "My-Awesome-Header": "header value" },
                parallelUploads: 2,
                resizeWidth: 1024,
                maxFiles: 10,
                acceptedFiles:['image/jpeg', 'image/png']
            }
           

        }

    },
     created(){
        this.cargarInformacion();
        
    },
    

    methods: {
        cargarInformacion(){
          
            fdcService.execute('editar', [this.vehiculoEditar]).then( r => {
                this.imagenes = r.imagenes
             
              
            })
        },
        cargarCiudades(){
            this.itemsCiudades = this.todasLasCiudades.filter( ciudad =>ciudad.idregion == this.regionSeleccionada)
        },

        
         traerModelos(idmarca = null){
          if (idmarca == null){
            fdcService.execute("modelos", [this.vehiculo.idmarca]).then( r => {
                this.itemsMV = r.modelos
            })
          }else{
            fdcService.execute("modelos", [idmarca]).then( r => {
                this.itemsMV = r.modelos
             })
          }
             
        },
        guardar(){
            fdcService.execute("caracteristicas", this.guardarCaracteristicas).then( r => {
               if (r.resultado == "ok"){
               this.dialogFracaso = false
                this.dialogExito = true
              } 
              else {
                this.dialogFracaso = true
                this.dialogExito = false
              }
            }).catch(error => {
              this.dialogFracaso = true
              this.dialogExito = false
            })
        } 
    }
}
</script>

<style>
.notificacion {
  font-size: 1.2em;
  color:black !important;
}
</style>