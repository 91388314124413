<template>
    
  
    <v-dialog
      persistent
      max-width="600px"
      v-model="show"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Perfil</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
               
              >
                <v-text-field
                  label="Nombre*"
                  required
                  
                  
                  v-model="perfil.nombre"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="6">
                  <v-text-field
                    label="Primer apellido*"
                    v-model="perfil.apellidopaterno"
                  >
                  </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Segundo apellido*"
                      v-model="perfil.materno"
                      required
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
               
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
               
              >
                <v-text-field
                  label="Rut*"
                  required
                  
                  
                  v-model="perfil.rut"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Correo electrónico"
                   v-model="perfil.email"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Teléfono Fijo"
                      type="tel"
                      v-model="perfil.telefonofijo"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Teléfono móvil"
                      type="tel"
                      v-model="perfil.telefonomovil"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                
             
                
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                
              >
              <v-row>
                <v-col cols="6">
                   <v-autocomplete
                    :items="itemsRegiones"
                    label="Región"
                    item-text="nombre"
                    item-value="idregion"
                    v-model="regionSeleccionada"
                    @change="cargarCiudades()"
                    
                  ></v-autocomplete>
                </v-col>
                <v-col  cols="6">
                  <v-autocomplete
                    :items="itemsCiudades"
                    label="Ciudad"
                    item-text="nombre"
                    item-value="idciudad"
                    v-model="perfil.idciudad"
                    
                  ></v-autocomplete>
                </v-col>
              </v-row>
               
                 
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col>
                   <v-checkbox
                   label="Datos públicos"
                   false-value=0
                   true-value=1
                   v-model="perfil.datospublicos"
                   >
                    
                   </v-checkbox>
                  </v-col>
                </v-row>
                
             
                
              </v-col>
            </v-row>
             <v-row v-if="dialogExito">
              <v-col cols="12">
              <h4 class="green--text">Datos guardados correctamente</h4>
                
              </v-col>
            </v-row>
          </v-container>
          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="show = false"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="guardarPerfil()"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>

      

      <v-dialog v-model="dialogFracaso" width="500" >
        <v-card >
        <v-card-title class="text-h5 grey lighten-2">
          Error al guardar
        </v-card-title>

        <v-card-text class="notificacion">
          Verifique los datos.
        </v-card-text>

      

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogFracaso = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>
     

    </v-dialog>
  
</template>


<script>
import { fdcService } from '../../fdc/dispatcher'
export default {


    props: {
     value: Boolean
    },
    computed: {
    show: {
        get () {
        return this.value
        },
        set (value) {
         this.$emit('input', value)
      }
    },
   
   
  }, data(){
        return{
            dialogExito: false,
            dialogFracaso: false,
            mensajeError: false,
            mensajeExito: false,
            itemsRegiones: [],
            itemsCiudades: [],
            todasLasCiudades: [],
            regionSeleccionada: null,
            perfil: {
                apellidomaterno: null,
                apellidopaterno: null,
                datospublicos: 0,
                email: null,
                idciudad: 0,
                nombre: null,
                rut: null,
                telefonofijo: null,
                telefonomovil: null,
            }

        }

    },
     created(){
        this.obtenerPerfil()
       
    },
    

    methods: {
       

        obtenerPerfil(){
            fdcService.execute('perfil').then( r => {
                this.perfil = r.usuario
                this.itemsRegiones = r.regiones
                this.itemsCiudades = r.ciudades
                this.todasLasCiudades = r.ciudades
                let ciudad = this.itemsCiudades.find( e => e.idciudad == this.perfil.idciudad)
                this.regionSeleccionada = ciudad.idregion
                
                
            }) 
        },
        guardarPerfil(){
            this.dialogExito = false;
            fdcService.execute('perfil-guardar', this.perfil).then( r => {
               if (r.resultado == "ok"){
                  this.dialogExito = true;

                }
                
            }).catch( error => {
             this.dialogFracaso = true;
          }) 
        },
        cargarCiudades(){
            this.perfil.idciudad = 0
            this.itemsCiudades = this.todasLasCiudades.filter( ciudad =>ciudad.idregion == this.regionSeleccionada)
        },

        registrarUsuario(){
          fdcService.execute("registrar", this.formularioRegistro).then( r => {
            
           
            if (r.resultado == "ok"){
               this.dialogExito = true;
             
            }else {
                this.dialogFracaso = true;
            }
          }).catch( error => {
             this.dialogFracaso = true;
          })

          
        }
    }
}
</script>

<style>
.notificacion {
  font-size: 1.2em;
  color:black !important;
}

</style>