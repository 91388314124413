<template >

  <div class="headertres" >
    <v-navigation-drawer
      class="hidden-md-and-up "
      v-model="drawer"
      fixed
      temporary
      width="320"
      
    >
      <v-list-item class="pa-5">
        <div class="logo">
          <img
            src="../../assets/images/fdc/portalautomotriz.png"
            alt="Portal Automotriz"
          />
        </div>
        <v-spacer></v-spacer>
        <v-btn
          class="close-icon"
          icon
          @click="drawer = !drawer"
          v-html="iconSvg(closeIcon)"
        >
        </v-btn>
      </v-list-item>

      <v-list>
        <v-list-item v-for="item in items" :key="item.title" :to="item.to" link>
          <v-list-item-content>
            <v-list-item-title v-if="item.modal == null" >{{ item.title }}</v-list-item-title>
            <v-list-item-title v-if="item.modal == 'Inicio'" ><a href="/">{{ item.title }}</a></v-list-item-title>
            <v-list-item-title v-if="item.modal == 'Login'" @click="openLogin = true">{{ item.title }}</v-list-item-title>
            <v-list-item-title v-if="item.modal == 'Registrar'" @click="openRegistro = true">{{ item.title }}</v-list-item-title>
            <v-list-item-title v-if="item.modal == 'Publicar'" @click="publicar()">{{ item.title }}</v-list-item-title>
            
          </v-list-item-content>
        </v-list-item>
        <!-- End mobile menu sidebar item list -->

        <v-list-group
          v-for="item in AyudaDropDownItems"
          :key="item.title"
          v-model="item.active"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            link
            :ripple="false"
            v-for="child in item.items"
            :key="child.title"
            
           
          >
            <v-list-item-content @click="abrirAyuda(child.url)" >
              <v-list-item-title v-text="child.title"  ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

      <div v-if="usuarioActivo">
        <v-list-group
          v-for="item in PerfilDropDownItems"
          :key="item.title"
          v-model="item.active"
        >
          <template v-slot:activator >
            <v-list-item-content>
              <v-list-item-title v-text="usuario.email"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            link
            :ripple="false"
            v-for="child in item.items"
            :key="child.title"
            :to="child.to"
          >
            <v-list-item-content @click="perfil(child)" >
              <v-list-item-title v-text="child.title"  ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        
      </div>
        
        <!-- End sidebar block dropdown item list -->

        
      </v-list>
      
    </v-navigation-drawer>
    <!-- End sidebar mobile menu -->

    <v-app-bar
      elevation="4"
      fixed
      class="pb-10"
    >
      <router-link to="/" class="logo">
        <slot name="logo">
        </slot>
      </router-link>
      <!-- End brand logo -->

      <v-toolbar-items class="hidden-xs-only hidden-sm-only height-auto ml--35">

        <v-btn :ripple="false" text href="/">Inicio</v-btn>
        <v-btn :ripple="false" v-if="usuarioActivo == false" text @click="openLogin = true">Ingresar</v-btn>
        <v-btn :ripple="false" v-if="usuarioActivo == false" text @click="openRegistro = true">Registrar</v-btn>
        <v-btn :ripple="false" text @click="publicar()">Publicar</v-btn>

        <v-menu
          open-on-hover
          bottom
          min-width="240"
          offset-y
          transition="scroll-y-reverse-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn :ripple="false" text v-bind="attrs" v-on="on">
              Ayuda
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              link
              v-for="(item, index) in AyudaDropDownItemsEscritorio"
              :key="index"
              @click="abrirAyuda(item.url)"

              
            >
              <v-list-item-title

              >
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
          
          
        </v-menu>
        

        <v-menu
          open-on-hover
          v-if="usuarioActivo"
          bottom
          min-width="240"
          offset-y
          transition="scroll-y-reverse-transition"
         
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn :ripple="false" text v-bind="attrs" v-on="on">
              {{usuario.email}}
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              link
              v-for="(item, index) in PerfilDropDownItemsEscritorio"
              :key="index"
              @click="perfil(item)"
              :to="item.to"

              
            >
              <v-list-item-title

              >
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
          
        </v-menu>
        <!-- End Home variants dropdown -->
        <div class="logo portal-automotriz">
          <img
            src="../../assets/images/fdc/portalautomotriz.png"
            alt="Portal Automotriz"
          />
        </div>
        
      </v-toolbar-items>
      <!-- End header menu item -->


      <v-spacer></v-spacer>

      <v-btn
        icon
        class="ma-0 pa-0 hidden-md-and-up"
        @click="drawer = !drawer"
        v-html="iconSvg(icon)"
      >
      </v-btn>
      <!-- End mobile menu icon -->

    </v-app-bar>
    <!-- End top header navbar -->

    <Login v-model="openLogin"></Login>
    <Registro v-model="openRegistro" @tieneCuenta="tieneCuenta"></Registro>
    <Perfil v-model="openPerfil" v-if="openPerfil"></Perfil>
    <Ayuda v-model="openAyudaPresentacion" v-if="openAyudaPresentacion" url="https://www.youtube.com/embed/IXey1Ui47RI"></Ayuda>
    <Ayuda v-model="openAyudaRegistroUsuario" v-if="openAyudaRegistroUsuario" url="https://www.youtube.com/embed/g9MBKqgBQM0"></Ayuda>
    <Ayuda v-model="openAyudaRegistroVehiculo" v-if="openAyudaRegistroVehiculo" url="https://www.youtube.com/embed/cGfqrpMPh6Q"></Ayuda>
    <Ayuda v-model="openAyudaMantenciones" v-if="openAyudaMantenciones" url="https://www.youtube.com/embed/sXCrSMQGBgc"></Ayuda>
    <Ayuda v-model="openAyudaPlan" v-if="openAyudaPlan" url="https://www.youtube.com/embed/49o-rPxyYlQ"></Ayuda>
    <Ayuda v-model="openAyudaDestacado" v-if="openAyudaDestacado" url="https://www.youtube.com/embed/DFBnLtQmtF4"></Ayuda>
  <br><br>
  </div>
</template>

<script>
import feather from "feather-icons";
import socialTwo from "../social/SocialTwo";
import Perfil from "../fdc/Perfil";
import Login from "../../views/fdc/Login.vue"
import Registro from "../../views/fdc/Registro.vue"
import Ayuda from "../../views/fdc/VideosAyuda.vue"
import { api } from '../../fdc/api';

export default {
  components: {
    socialTwo,
    Login,
    Registro,
    Ayuda,
    Perfil
  },
  data: () => ({
    drawer: false,
    openLogin: false,
    openRegistro: false,
    openAyudaPresentacion: null,
    openAyudaRegistroUsuario: null,
    openAyudaRegistroVehiculo: null,
    openAyudaMantenciones: null,
    openAyudaPlan: null,
    openAyudaDestacado: null,
    openPerfil: false,
    usuarioActivo: false,
    urlSeleccionada: null,
    usuario: null,
    items: [
      { title: "Inicio", to: null, modal: "Inicio" },
      { title: "Ingresar", to: null, modal: 'Login' },
      { title: "Registrar", to: null, modal: 'Registrar' },
      { title: "Publicar", to: null, modal: 'Publicar' },
    ],
    AyudaDropDownItemsEscritorio: [
      { title: "Presentacion", url: "Presentacion" },
      { title: "Registro de usuario", url: "Usuarios"  },
      { title: "Registro de vehiculos", url: "Vehiculos"  },
      { title: "Mantenciones", url: "Mantenciones"  },
      { title: "Plan de compras", url: "Plan"  },
      { title: "Destacados y Posicionamiento", url: "Destacados"},
    ],
    PerfilDropDownItemsEscritorio: [
      { title: "Perfil", to: null },
      { title: "Mis vehiculos", url: null, to:"/misvehiculos"  },
      { title: "Cerrar sesion",  }
     ,
    ],
   

    // Bellow mobile menu items
   
    AyudaDropDownItems: [
      {
        items: [
          { title: "Presentacion", url: "Presentacion" },
          { title: "Registro de usuario", url: "Usuarios"  },
          { title: "Registro de vehiculos", url: "Vehiculos"  },
          { title: "Mantenciones", url: "Mantenciones"  },
          { title: "Plan de compras", url: "Plan"  },
          { title: "Destacados y Posicionamiento",url: "Destacados"  },
        ],
        title: "Ayuda",
      },
    
    ],
    PerfilDropDownItems: [
      {
        items: [
          { title: "Perfil", to: null},
          { title: "Mis vehiculos", url: null, to:"/misvehiculos" },
          { title: "Cerrar sesion",  }
        ],
        title: "Mi usuario",
      },
    
    ],

    

    icon: "menu",
    closeIcon: "x",
  }),
   mounted(){
      this.usuarioIngresado()
    },
    methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },

    cerrarSesion(){
      localStorage.removeItem('token')
      localStorage.removeItem('usuario')
      
      this.$router.push('/')
      location.reload()
      
    },

    perfil(item){
      if(item.title == "Cerrar sesion") this.cerrarSesion()
      if(item.title == "Perfil") this.openPerfil = true;
    },

    tieneCuenta(){
      this.openLogin = true;
    },
   
    usuarioIngresado(){
     let usuario = api.getUsuario()
     
    
     if(usuario.token != false){
      this.items = this.items.filter(i => i.title != "Registrar" && i.title != "Ingresar")
      this.usuario = usuario.usuario
      
      this.usuarioActivo = true;
      
     } else {
      this.usuarioActivo = false
     }
    },
    publicar(){
      if (this.usuarioActivo == true){
        
        if (this.$route.path.match(/misvehiculos.*/)) this.$emit("desdePublicar")
        else this.$router.push({path: "/misvehiculos/new"})
      } 
      else this.openLogin = true;
    },
    abrirAyuda(item){
      
      this.openAyudaPresentacion = false;
      this.openAyudaRegistroUsuario = false;
      this.openAyudaRegistroVehiculo = false;
      this.openAyudaMantenciones = false;
      this.openAyudaPlan = false;
      this.openAyudaDestacado = false;
      if(item == "Presentacion") this.openAyudaPresentacion = true;
      if(item == "Usuarios") this.openAyudaRegistroUsuario = true;
      if(item == "Vehiculos") this.openAyudaRegistroVehiculo = true;
      if(item == "Mantenciones") this.openAyudaMantenciones = true;
      if(item == "Plan") this.openAyudaPlan = true;
      if(item == "Destacados") this.openAyudaDestacado = true;
    }
  },
};
</script>

<style lang="scss">
.feather-menu {
  color: #c6c9d8;
}
.sticky{
  position: sticky !important;
}
.theme--dark.v-app-bar.v-toolbar.v-sheet {
    background-color: #fff!important;
}

.headertres .v-btn__content {
  color: black!important;
}
.v-toolbar {
  box-sizing: content-box;
}

.portal-automotriz  {
  right: 0;
  position: absolute;
}

@media (max-width: 1160px) {
  .portal-automotriz {
    display: none;
  }
}

</style>
