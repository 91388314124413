<template>
    
  <v-row justify="center">
    <v-dialog
      persistent
      max-width="600px"
      v-model=show
    >
      <v-card v-if="recuperarClave == false">
        <v-card-title colo>
          <span class="text-h5">Ingresar</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
            
           
              
              <v-col cols=12>
                <v-text-field
                  label="Usuario*"
                  required
                  v-model="login.username"
                ></v-text-field>
              </v-col>
              <v-col cols=12>
                <v-text-field
                  label="Contraseña*"
                  type="password"
                  v-model="login.password"
                  required
                ></v-text-field>
              </v-col>
              
              
            </v-row>
            
            <v-row  v-if="datosIncorrectos">
              <h6  class=" red--text">Datos incorrectos, intente otra vez</h6>
            </v-row>
          </v-container>
          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn
            color="red darken-1"
            text
            @click="recuperarClave = true"
          >
            Recuperar clave
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="show = false"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="loginUsuario()"
            
          >
            Ingresar
          </v-btn>
        </v-card-actions>
      </v-card>


      <v-card v-if="recuperarClave == true">
        <v-card-title colo>
          <span class="text-h5">Recuperar contraseña</span>
        </v-card-title>
        <v-card-text>
          Se le enviará un link de recuperación a su correo electrónico.
          <v-container>
            <v-row>
              <v-col cols=12>
                <v-text-field
                  label="Ingrese su correo electronico*"
                  required
                  v-model="recuperacion.email"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row  v-if="datosIncorrectosRecuperacion">
              <h6  class=" red--text">Datos incorrectos, intente otra vez</h6>
            </v-row>
          </v-container>
          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn
            color="red darken-1"
            text
            @click="recuperarClave = false"
          >
            Login
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="show = false"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="recuperarClaveUsuario(), show = false"
            
          >
            Recuperar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import { fdcService } from '../../fdc/dispatcher'
export default {
    props: {
     value: Boolean
    },
    computed: {
    show: {
        get () {
        return this.value
        },
        set (value) {
         this.$emit('input', value)
      }
    }
  },
    data() {
      return{
        login:{
          username: null,
          password: null,

        },
        recuperacion: {
          email: null
        },
        
        datosIncorrectos: false,
        datosIncorrectosRecuperacion: false,
        recuperarClave: false,
      }
    },
    
    methods:{
      loginUsuario(){
        fdcService.execute("login", this.login).then(r => {
        
          if(r.resultado == "ok"){
             this.datosIncorrectos = false;
             this.show = false;
             localStorage.setItem("usuario", JSON.stringify(r.usuario))
             localStorage.setItem("token", JSON.stringify(r.token))
             location.reload()
          }else{
             this.datosIncorrectos = true;
          }

        }).catch( error => {
          this.datosIncorrectos = true;
        })
      },
      recuperarClaveUsuario(){
        fdcService.execute("recuperar", this.recuperacion).then( r => {
          c
           if(r.resultado == "ok"){
            this.datosIncorrectosRecuperacion = false;
            this.show = false;
          }else{
             this.datosIncorrectosRecuperacion = true;
          }
        }).catch( error => {
          this.datosIncorrectosRecuperacion = true;
        })
      }
    }
}
</script>

<style>


</style>