<template>
    <v-row class=" mb-10 pt-5 pl-5 pr-5 mr-1">
        <v-col cols="12"  sm="12" md="6"  lg="1" xl="1" class="mr-0 pr-0 ml-0 pr-0">
            <v-autocomplete 
                solo
                label="Region"
                placeholder="Region"
                :items="itemsRegion"
                item-text="nombre"
                item-value="idregion"
                v-model="buscador.idregion"
                clearable
            >
            </v-autocomplete>
        </v-col>

        <v-col cols="12" sm="12" md="6"  lg="1" xl="1" class="mr-0 pr-0 ml-0 pr-0">
            <v-autocomplete 
                solo
                label="Tipo"
                placeholder="Tipo"
                :items="itemsTipo"
                item-text="nombre"
                item-value="idtipo"
                v-model="buscador.idtipo"
                clearable
            >
            </v-autocomplete>   
        </v-col>
        <v-col cols="12" sm="12" md="6"  lg="1" xl="1" class="mr-0 pr-0 ml-0 pr-0">
            <v-autocomplete 
                solo
                label="Marca"
                placeholder="Marca"
                :items="itemsMarca"
                item-text="nombre"
                item-value="idmarca"
                v-model="buscador.idmarca"
                @change="traerModelos()"
                clearable
            >
            </v-autocomplete>  
        </v-col> 
        <v-col cols="12" sm="12" md="6"  lg="1" xl="1" class="mr-0 pr-0 ml-0 pr-0">
            <v-autocomplete 
                solo 
                label="Modelo/Version"
                placeholder="Modelo/Version"
                :items="itemsMV"
                item-text="nombre"
                item-value="idmodelo"
                v-model="buscador.idmodelo"
                clearable
            >
            
            
            </v-autocomplete>   
        </v-col>
        <v-col cols="12" sm="12" md="6"  lg="1" xl="1" class="mr-0 pr-0 ml-0 pr-0">
            <v-autocomplete 
                solo 
                label="Año desde"
                placeholder="Año desde"
                :items="itemsAnoDesde"
                @change="ajustarAnos()"
                v-model="buscador.ano_desde"
                clearable
            >
            
            
            </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12" md="6"  lg="1" xl="1" class="mr-0 pr-0 ml-0 pr-0"> 
            <v-autocomplete 
                solo 
                label="Año hasta"
                placeholder="Año hasta"
                :items="itemsAnoHasta"
                v-model="buscador.ano_hasta"
                clearable
            >
            </v-autocomplete>   
        </v-col> 
        <v-col cols="12" sm="12" md="6"  lg="2" xl="2" class="mr-0 pr-0 ml-0 pr-0">
            <v-autocomplete 
                solo 
                label="Precio desde"
                placeholder="Precio desde "
                :items="preciosDesde"
                item-text="text"
                item-value="value"
                v-model="buscador.precio_desde"
                clearable
            >
            </v-autocomplete>
        </v-col>
        <v-col  cols="12" sm="12" md="6"  lg="2" xl="2" class="mr-0 pr-0 ml-0 pr-0">
            <v-autocomplete 
                solo 
                label="Precio hasta"
                placeholder="Precio hasta"
                :items="preciosHasta"
                item-text="text"
                item-value="value"
                v-model="buscador.precio_hasta"
                clearable
            >
            </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12" md="6"  lg="1" xl="1" class="mr-0 pr-0 ml-0 pr-0 ">
            <v-btn
            color="warning"
            block
            class="mt-2"
            @click="buscar()"
            >
            Buscar
            </v-btn>
        </v-col>
        <v-col cols="12" sm="12" md="6"  lg="1" xl="1" class="mr-0 pr-0 ml-0 pr-0 ">
            <v-btn
            color="primary"
            block
            class="mt-2"
            @click="limpiarBusqueda()"
            >
            Limpiar
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import { fdcService } from '../../fdc/dispatcher'

export default {
    props:["itemsRegion", "itemsTipo","itemsMarca","itemsMin","todosLosPrecios","todosLosAnos","itemsAnoDesde","itemsMax"],
    data() {
        return{
           // itemsRegion: null,
            //itemsTipo: null,
            //itemsMarca: null,
            itemsMV: null,
            //itemsAnoDesde: null,
            itemsAnoHasta: null,
            //itemsMin: null,
            //itemsMax: null,
            marcaSeleccionada: null,
            desdeSeleccionado: null,
            //todosLosPrecios: [],
            //todosLosAnos: [],
            encontrados: [],
            buscador:{
                idregion: 0,
                idtipo: 0,
                idmarca: 0,
                idmodelo: 0,
                ano_desde: 1940,
                ano_hasta: 2022,
                precio_desde: 0,
                precio_hasta: 29000000
            }
           
        }
    },
    created(){
        // console.log(this.buscador)  
    },

    computed: {
        preciosDesde(){
            let retorno = []
            this.todosLosPrecios.forEach(element => {
                if (element < this.buscador.precio_hasta) {
                    retorno.push({
                        text: element.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'),
                        value: element
                    })
                }
            });
            return retorno
        },
        preciosHasta() {
            let retorno = []
            this.todosLosPrecios.forEach(element => {
                if (element > this.buscador.precio_desde) {
                    retorno.push({
                        text: element.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'),
                        value: element
                    })
                }
            });
            return retorno
        }
    },
    
    methods:{
        buscar(){
            this.busquedaActiva = true
            if(this.buscador.precio_desde == null) this.buscador.precio_desde = 0;
            if(this.buscador.ano_desde == null) this.buscador.ano_desde = this.todosLosAnos[0];
            if(this.buscador.ano_hasta == null) this.buscador.ano_hasta = this.todosLosAnos[this.todosLosAnos.length - 1];
        
            fdcService.execute("buscar", this.buscador).then( r => {
                
                this.$emit("buscar", {encontrados: r.encontrados})
            })
        },
        limpiarBusqueda(){
            this.buscador = {
                idregion: 0,
                idtipo: 0,
                idmarca: 0,
                idmodelo: 0,
                ano_desde: this.todosLosAnos[0],
                ano_hasta: this.todosLosAnos[this.todosLosAnos.length - 1],
                precio_desde:  this.todosLosPrecios[0],
                precio_hasta: this.todosLosPrecios[this.todosLosPrecios.length - 1]
            }
            this.buscar()
        },
        ajustarPrecios(){
            this.itemsMax = this.todosLosPrecios.filter(e => e > this.buscador.precio_desde)
        },
        ajustarAnos(){ 
            this.itemsAnoHasta = this.todosLosAnos.filter(e => e > this.buscador.ano_desde)
        },
        traerModelos(){
             fdcService.execute("modelos", [this.buscador.idmarca]).then( r => {
                this.itemsMV = r.modelos
             })
        },
    }
}
</script>
<style>
 
</style>