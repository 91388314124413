<template>
  <!-- Start Footer Style Two  -->
  <div
    class="footer-style-2 ptb--30 bg_image bg_image--1  "
    data-black-overlay="6"
  >
    <div class="wrapper plr--50 plr_sm--20">
      <v-row align="center">
        <v-col lg="4" md="4" sm="6" cols="12">
           <div class="inner text-md-right text-center mt_md--20 mt_sm--20">
            <div class="text">
              <p>
                2022 © Todos los derechos reservados para PrecioConversable.

              </p>
              <p><a role="button"  @click="mostrarTerminos()">Términos del Servicio</a>  | <a href="">Política de Privacidad</a> </p>
            </div>
          </div>
        </v-col>
        <!-- End .col -->
        <v-col lg="4" md="4" sm="6" cols="12">
          <div class="inner text-center">
            <ul class="social-share rn-lg-size d-flex justify-center liststyle">
              <li v-for="(social, i) in socialList" :key="i">
                <a target="_blank" :href="social.url"
                  ><i class="fab" :class="social.icon"></i
                ></a>
              </li>
            </ul>
          </div>
        </v-col>
        <!-- End .col -->
        <v-col lg="4" md="4" sm="12" cols="12">
          <div class="inner text-center">
             <p>Desarrollado por: &nbsp; 
                <a   
                
                > Fábrica de codigo
                </a>
              </p> 
          </div>
       
        </v-col>
        <!-- End .col -->
      </v-row>
    </div>
  <!-- End Footer Style Two  -->
  
  <Terminos v-model="dialog"></Terminos>
  
  </div>

 
</template>


<script>
import Terminos from '../../views/fdc/Terminos.vue'


  export default {

    components:{
      Terminos
    },

    data() {
      return {
        dialog: false,
        socialList: [
          {
            icon: "fa-facebook-f",
            url: "https://www.facebook.com/precio.conversable.37/",
          },
         
          {
            icon: "fa-instagram",
            url: "https://www.instagram.com/precioconversable/",
          },
          {
            icon: "fa-youtube",
            url: "https://www.youtube.com/channel/UCNwkWP82NEFHaKqoKWtUbfw",
          },
        ],
      };
    },
    methods:{
      mostrarTerminos(){
        this.dialog = true;
      }
    }
  };
</script>
<style scoped>

</style>
