<template>
    <div>
        <v-col class="pr-5 pl-5" >
            
            <Header>
                <img slot="logo" src="../../assets/images/fdc/Logo.png" >
            </Header>
           
            <v-row v-if="vehiculo" class="mt-10 pt-10">
                <v-col cols="12">
                    <v-row>
                         <div class="pl-5  text-h5 text-sm-h4 text-md-h3" >{{vehiculo.titulo}}</div>
                    </v-row>
                </v-col>
            
                <v-col cols="12"  sm="12" md="12"  lg="8" xl="8">
    
                            <v-card class="mb-5">
                                <div v-if="vehiculo.fotografias.length > 0">
                                    <v-card-title>
                                        Fotografías
                                    </v-card-title>
                                    <Carousel
                                    :speed=10000
                                    autoplay
                                    :items-per-view=1
                                    :autoplay-timeout=5000
                                    
                                    >
                                    <v-img v-for="foto in vehiculo.fotografias" :key="foto.url" :src="foto.url"></v-img>
                                    </Carousel>
                                </div>
                                
                               <div v-if="vehiculo.caracteristicas.length > 0">
                                <v-card-title>
                                    Características
                                </v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col v-for="linea in vehiculo.caracteristicas" :key="linea.label" cols="6" class="m-0 p-0">
                                        
                                            <span class="textofuerte">{{linea.label}}:</span>
                                            &nbsp; 
                                            <span>{{linea.text}}</span>
                                                
                                        
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                               </div>
                                
                                <div v-if="vehiculo.equipamiento.length > 0">
                                    <v-card-title>
                                        Detalles
                                    </v-card-title>
                                    <v-card-text class="m-0 p-0">
                                        <v-row class="m-0 p-0 loscheck">
                                        <v-col v-for="linea in vehiculo.equipamiento" :key="linea.label" cols="6" class="m-0 p-0">
                                        
                                                <v-checkbox class="m-0 p-0"  v-model="linea.checked" readonly :label="linea.label"></v-checkbox>
                                            
                                        
                                        
                                        </v-col>
                                    </v-row>
                                    </v-card-text>
                                </div>
    
                                <div v-if="vehiculo.mantenciones.length > 0">
                                <v-card-title>
                                    Mantenciones
                                </v-card-title>
                                <v-timeline
                                        align-top
                                        dense
                                    >
                                        <v-timeline-item
                                        color="primary"
                                        small
                                        v-for="mantencion in vehiculo.mantenciones" :key="mantencion.comentario"
                                        >
                                        <v-row class="pt-1">
                                            <v-col >
                                                <strong>{{devolverFecha(mantencion.fecha)}}</strong>
                                                <div class="text-caption">
                                                    {{mantencion.comentario}}
                                                </div>
                                            </v-col>
                                        </v-row>
                                        </v-timeline-item>
                                    </v-timeline>
                                    <br>
                               </div>
                                
                                
                                 
    
                            </v-card>
                   
                   
                </v-col>
                <v-col sm="12" md="12"  lg="4" xl="4">
                    <Publicidad :publicidad="publicidad" v-if="mostrarPublicidad"></Publicidad>
    
                </v-col>
    
                
            </v-row>
        </v-col>
      
        <Footer></Footer>
    </div>  
    </template>
    
    <script>
    import { fdcService } from '../../fdc/dispatcher'
    import Header from "../../components/header/HeaderThree";
    import Publicidad from "../../components/fdc/Publicidad";
    import Carousel from "vue2-simple-carousel";
    import Footer from "../../components/footer/FooterTwo";
import moment from 'moment';

    export default {
        components:{
            Header,
            Publicidad,
            Carousel,
            Footer
        },
        data(){
            return {
                vehiculo: null,
                publicidad: null,
                mostrarPublicidad: false
            }
            
        },
        created(){
        this.cargarVehiculo()
        this.cargarPublicidad()
        },
        methods:{
            cargarPublicidad(){
                fdcService.execute("landing").then( r => {
                    this.publicidad = r.publicidad
                    this.mostrarPublicidad = true
                })
            },
            cargarVehiculo(){
                fdcService.execute("vehiculo", [this.$route.params.id]).then(r => {
                    this.vehiculo = r
                   
                })
            },
             cargarCiudades(){
                this.itemsCiudades = this.todasLasCiudades.filter( ciudad =>ciudad.idregion == this.regionSeleccionada)
            },
    
            abrirDocumento(url){
                window.open(url, "_blank")
            },
            devolverFecha(fecha){
                return moment(fecha).format("DD-MM-YYYY")
            }
                
        }
    }
    </script>
    
    <style>
    .textofuerte{
        font-weight: bold;
    }
    .carousel__button span {
        font-size: 3em;
        background-color: #ffff;
    }
    
    .loscheck .v-messages {
      
       height: 0%!important;
       width: 0%!important;
       min-height: 0%!important;
    
    }
    .loscheck .v-input-control {
      padding: 0px 0px!important;
      margin: 0px 0px 0px 0px!important;
       
    
    }
    
    .loscheck .v-input--selection-controls {
         margin-top: 0px; 
        padding-top: 0px; 
    }
    
    </style>