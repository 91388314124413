<template>
    

<div>
    <Header>
            <img slot="logo" src="../../assets/images/fdc/Logo.png" >
    </Header>

    <br><br><br><br><br><br><br><br><br>
    <v-row v-responsive="['hidden-all','lg','xl']">
        <v-col></v-col>
        <v-col>
            <v-card elevation="500" v-if="confirmacionExitosa == false">
                <v-card-title >
                <span class="text-h5">Nueva contraseña</span>
                </v-card-title>
                <v-card-text>
              
                <v-container>
                    <v-row>
                    <v-col cols=12>
                        <v-text-field
                        label="Nueva contraseña"
                        required
                        v-model="confirmacion.password"
                         type="password"
                        ></v-text-field>
                    </v-col>
                    <v-col cols=12>
                        <v-text-field
                        label="Confirmar nueva contraseña"
                        required
                        v-model="confirmacion.confirmacion"
                         type="password"
                        ></v-text-field>
                    </v-col>
                    </v-row>
                    <v-row  v-if="datosIncorrectosRecuperacion">
                    <h6  class=" red--text">Datos incorrectos, intente otra vez</h6> 
                    </v-row>
                </v-container>
                
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
               
               
                <v-btn
                    color="blue darken-1"
                    text
                    @click="confirmarRecuperacion()"
                    
                >
                    Cambiar
                </v-btn>
                </v-card-actions>
            </v-card>

            <v-card elevation="500" v-else>
                <v-card-title >
                <span class="text-h5">Contraseña Actualizada</span>
                </v-card-title>
                <v-card-text>
                    Ya puede iniciar sesion con su nueva contraseña
                
                
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
               
               
                <v-btn
                    color="blue darken-1"
                    text
                    to="/"
                    
                >
                    Volver al inicio
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
        <v-col></v-col>
    </v-row>

    <v-row v-responsive="['hidden-all','xs', 's','md']">
       
        <v-col>
            <v-card elevation="500" v-if="confirmacionExitosa == false" >
                <v-card-title >
                <span class="text-h5">Nueva contraseña</span>
                </v-card-title>
                <v-card-text>
              
                <v-container>
                    <v-row>
                    <v-col cols=12>
                        <v-text-field
                        label="Nueva contraseña"
                        required
                        v-model="confirmacion.password"
                         type="password"
                        ></v-text-field>
                    </v-col>
                    <v-col cols=12>
                        <v-text-field
                        label="Confirmar nueva contraseña"
                        required
                        v-model="confirmacion.confirmacion"
                        type="password"
                        ></v-text-field>
                    </v-col>
                    </v-row>
                    <v-row  v-if="datosIncorrectosRecuperacion">
                    <h6  class=" red--text">Datos incorrectos, intente otra vez</h6>
                    </v-row> 
                </v-container>
                
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
               
               
                <v-btn
                    color="blue darken-1"
                    text
                    @click="confirmarRecuperacion()"
                    
                >
                    Cambiar
                </v-btn>
                </v-card-actions>
            </v-card>

            <v-card elevation="500" v-else>
                <v-card-title >
                <span class="text-h5">Contraseña Actualizada</span>
                </v-card-title>
                <v-card-text>
                    Ya puede iniciar sesion con su nueva contraseña
                
                
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
               
               
                <v-btn
                    color="blue darken-1"
                    text
                    to="/"
                    
                >
                    Volver al inicio
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
        
    </v-row>
    <br><br><br><br>
    
        
    <v-footer  app bottom fixed padless >
        <Footer class="footer"></Footer>
    </v-footer>
    </div>
       
</template>

<script>
import Header from "../../components/header/HeaderThree";
import Footer from "../../components/footer/FooterTwo";
import { fdcService } from '../../fdc/dispatcher';



export default {
     components:{
        Header,
        Footer,
    },
    data(){
        return {
            confirmacion: {
                codigo: this.$route.params.codigo,
                token: this.$route.params.token,
                password: null,
                confirmacion: null
            },
            confirmacionExitosa: false,
            datosIncorrectosRecuperacion: false
        }
    },
    mounted(){
       
    },
    methods:{
        confirmarRecuperacion(){
            fdcService.execute("recuperar-confirmar", this.confirmacion).then( r => {
                if (r.resultado == "ok") {
                    this.confirmacionExitosa = true
                    this.datosIncorrectosRecuperacion = false;
                }else {
                    this.confirmacionExitosa = false
                    this.datosIncorrectosRecuperacion = true;
                }
            }).catch( error => {
                 this.confirmacionExitosa = false
                 this.datosIncorrectosRecuperacion = true;
            })
        }
    }

}
</script>

<style>
.footer{
    width: 100%;
}


</style>