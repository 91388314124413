import { diccionario } from './rutas.ts'

/* Declaraciones */
// const _url = 'https://pc3.fabricadecodigo.dev:8084'
const _url = "https://api.precioconversable.com";
// const _url = "http://localhost:8000";
const _sitio = 'fdc'
const _token = 'fdc'
const rutas = diccionario
//const user = useUserSession()
const user = JSON.parse(localStorage.getItem('token'))
const datosusuario = JSON.parse(localStorage.getItem('usuario'))

/* Exportación */
export const api = {
  rutas,
  getHost,
  getRuta,
  getHeader,
  getUsuario
}

function getHost(part) {
  return `${_url}${part}`
}

function getUsuario(){
  let usuario = {usuario: false, token: false}
  
  if (datosusuario !== undefined && datosusuario !== '' && datosusuario !== null)usuario.usuario = datosusuario
  if (user !== undefined && user !== '' && user !== null) usuario.token = user

  return usuario
}

function getRuta(servicio) {
  return rutas.find((x) => x.servicio == servicio)
}
function getHeader(ruta, headerAdicional = {}) {
  // eslint-disable-next-line
  let headers = {}

  if (user !== undefined && user !== '' && user !== null){
    if (user !== undefined && user !== '' && user !== null) {
      headers = {
        Authorization: 'Bearer ' + user,
      }
    }
  }
 
  if (ruta.headers !== undefined && headerAdicional === null) {
    ruta.headers.forEach((x) => {
      if (x === 'sitio') {
        Object.assign(headers, { sitio: _sitio })
        Object.assign(headers, { token: _token })
      }
      if (x === 'multipart') {
        Object.assign(headers, { 'Content-Type': 'multipart/form-data' })
      }
    })
  }
  if (headerAdicional !== null) {
    Object.assign(headers, headerAdicional)
  }
  return { headers: headers }
}
