import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/es5/services/goto";
import Missing from "../views/404.vue";
import Landing from "../views/fdc/Landing.vue";
import ConfirmarRegistro from "../views/fdc/ConfirmarRegistro.vue";
import RecuperarClave from "../views/fdc/RecuperarClave.vue";
import Publicacion from "../views/fdc/Publicacion.vue";
import MisVehiculos from "../views/fdc/MisVehiculos.vue";
import Vehiculo from "../views/fdc/Vehiculo.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Landing,
    meta: {
      title: "Precio conversable",
    },
  },
  {
    path: "/automotriz/publicacion/:id",
    component: Publicacion,
    meta: {
      title: "Publicación",
    },
  },
  {
    path: "/automotriz/vehiculo/:id",
    component: Vehiculo,
    meta: {
      title: "Vehículo",
    },
  },
  {
    path: "/misvehiculos/:new",
    component: MisVehiculos,
    meta: {
      title: "Mis vehículos",
    },
  },
  {
    path: "/misvehiculos",
    component: MisVehiculos,
    meta: {
      title: "Mis vehículos",
    },
  },

  {
    path: '/usuario/confirmar/:email/:token',
    component: ConfirmarRegistro,
    meta: { 
      title: "Confirmar registro",
    },
  },
  {
    path: '/usuario/recuperar/:codigo/:token',
    component: RecuperarClave,
    meta: { 
      title: "Recuperar Clave",
    },
  },

];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
