<template>
    <div>
        <Header @desdePublicar="desdePublicar">
                <img slot="logo" src="../../assets/images/fdc/Logo.png" >
        </Header>
       
        <v-row class="mt-10 mt-10 pt-10 ml-5 mr-5">
            <v-col cols="12" sm="12" md="12" lg="8" xl="8" class="mt-10 mb-10">
                 <v-row >
                    <v-col></v-col>
                    <v-col></v-col>
                    <v-col></v-col>
                    <v-col>
                        <v-btn  block  class="blue  mx-2" @click="vehiculoEditar = null, paso = 1, showPasos = true">Nuevo vehiculo</v-btn>
                    </v-col>
                </v-row>
                <v-simple-table  class="pl-5 pr-5 mt-15 pt-5" >
                    <template v-slot:default>
                        <thead class="grey">
                            <tr>
                            <th class="text-left">
                                Ver 
                            </th>
                            <th class="text-left">
                                Vehículo
                            </th>
                            <th class="text-left">
                                Valor
                            </th>
                            <th class="text-left">
                                Administración
                            </th>
                            <th class="text-left">
                                Estado
                            </th>
                            <th class="text-left">
                                Acciones
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="vehiculo in vehiculos" :key="vehiculo.id_vehiculo"
                                class="grey lighten-3"
                            >
                                <td>
                                    <v-row >
                                        <v-col cols="5" class="mt-1" v-if="vehiculo.id_publicacion && vehiculo.privado == 0">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon color="orange"   v-bind="attrs"
                                                    v-on="on" @click="abrirPublicacion(vehiculo.id_publicacion)">mdi-tag</v-icon>
                                                </template>
                                                <span>Abrir publicacion</span>
                                            </v-tooltip>
                                            
                                           <!--  <v-btn  x-small  class="orange  mx-2 " @click="abrirPublicacion(vehiculo.id_publicacion)" >Publicación</v-btn> -->
                                        </v-col>
                                        <v-col cols="5" class="mt-1" v-if="vehiculo.id_vehiculo">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon color="blue"   v-bind="attrs"
                                                    v-on="on" @click="abrirVehiculo(vehiculo.id_vehiculo)">mdi-car-hatchback</v-icon>
                                                </template>
                                                <span>Abrir vehículo</span>
                                            </v-tooltip>
                                         
                                         <!--    <v-btn  x-small  class="blue  mx-2 " @click="abrirVehiculo(vehiculo.id_vehiculo)">Vehículo</v-btn> -->
                                        </v-col>
                                    </v-row>
                                    
                                    
                                </td>
                                <td>{{vehiculo.vehiculo}}</td>
                                <td>{{formatoPesosChilenos(vehiculo.valor)}}</td>
                                <td>
                                        <v-row class="administracion">
                                            <v-col cols="2" class=" ml-0 mr-0 pl-0 pr-0" >
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="blue" v-bind="attrs"  v-on="on"  @click="vehiculoEditar = vehiculo.id_vehiculo, paso = 1, showPasos = true">mdi-text-box</v-icon>
                                                    </template>
                                                    <span>Llenar información del vehículo</span>
                                                </v-tooltip>
                                            </v-col>
                                            <v-col cols="2"  class="ml-0 mr-0 pl-0 pr-0">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="green" v-bind="attrs"  v-on="on"  @click="vehiculoEditar = vehiculo.id_vehiculo, paso = 2, showPasos = true">mdi-receipt</v-icon>
                                                    </template>
                                                    <span>Llenar características del vehículo</span>
                                                </v-tooltip>
                                            </v-col>
                                            <v-col cols="2"  class="ml-0 mr-0 pl-0 pr-0">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="orange" v-bind="attrs"  v-on="on"  @click="vehiculoEditar = vehiculo.id_vehiculo, paso = 3, showPasos = true">mdi-image-multiple</v-icon>
                                                    </template>
                                                    <span>Imágenes del vehículo</span>
                                                </v-tooltip>
                                            </v-col>
                                            <v-col cols="2"  class="ml-0 mr-0 pl-0 pr-0">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="red" v-bind="attrs"  v-on="on" @click="openEliminar = true, vehiculoEditar = vehiculo.id_vehiculo">mdi-trash-can</v-icon>
                                                    </template>
                                                    <span>Eliminar vehículo</span>
                                                </v-tooltip>
                                            </v-col>
                                            <v-col cols="2"  class="ml-0 mr-0 pl-0 pr-0">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="light-blue" v-bind="attrs"  v-on="on" @click="vehiculoEditar = vehiculo.id_vehiculo, vehiculoInt = vehiculo.idvehiculo, openMantencion = true" >mdi-car</v-icon>
                                                    </template>
                                                    <span>Mantenciones del vehículo</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                </td>
                                <td>
                                    <v-chip v-if="vehiculo.pub_estado == 101"  x-small class="orange  mx-2 white--text">Público</v-chip>
                                    <v-chip v-if="vehiculo.pub_estado == 102"  x-small class="green  mx-2 white--text">Privado</v-chip>
                                    <v-chip v-if="vehiculo.pub_estado == 103"  x-small class="light-blue  mx-2 white--text">En venta</v-chip>  
                                </td>
                                <td>
                                    <v-row>
                                        <v-col cols="12" class="mt-1" v-if="!vehiculo.id_publicacion">
                                            <v-btn  x-small  class="orange  mx-2 white--text" v-if="vehiculo.id_publicacion == null || vehiculo.idpublicacion == null" @click="vehiculoEditar = vehiculo.id_vehiculo, paso = 4, showPasos = true">Publicar</v-btn>
                                        </v-col>
                                        <v-col cols="12" class="mt-1" v-else>
                                            <v-btn  x-small  class="blue  mx-2 white--text" v-if="vehiculo.pub_publicacion = 201" @click="vehiculoEditar = vehiculo.id_vehiculo, paso = 4, showPasos = true">Cambiar solicitud</v-btn>
                                        </v-col>
                                    </v-row>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                       
                </v-simple-table>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="4" xl="4">
                <Publicidad :publicidad="this.publicidad" v-if="mostrarPublicidad"></Publicidad>
            </v-col>
        </v-row>


        
        <v-footer   bottom padless >
            <Footer class="footer"></Footer>
        </v-footer>

        <v-dialog      
            max-width="1000"
            v-model="showPasos"
            >
            <v-card >
                <v-card-title>
                <span class="  text-h5 text-sm-h4 text-md-h3"> Mi vehículo</span>
                </v-card-title>
                <v-card-text>
                    <PasoEdicion @accion="accionPaso" @cerrarPaso="cerrarPaso" :id="vehiculoEditar" v-if="paso == 1"></PasoEdicion>
                    <PasoCaracteristicas @accion="accionPaso" @cerrarPaso="cerrarPaso" :id="vehiculoEditar" v-if="paso == 2"></PasoCaracteristicas>
                    <PasoImagenes @accion="accionPaso" @cerrarPaso="cerrarPaso" :id="vehiculoEditar" v-if="paso == 3"></PasoImagenes>
                    <Publicar  @accion="accionPaso" @cerrarPaso="cerrarPaso" :id="vehiculoEditar" v-if="paso == 4"></Publicar>
                </v-card-text>
            </v-card>
        </v-dialog>

        <Mantencion  v-model="openMantencion"  :id="vehiculoEditar" :vehiculoInt="vehiculoInt" v-if="openMantencion"></Mantencion>

        <v-dialog v-model="openEliminar" max-width="500">
            <v-card>
                <v-card-title>
                    Seguro que desea eliminar el vehículo?
                </v-card-title>
                 <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            text
                            @click="eliminarVehiculo()"
                        >
                            Si
                        </v-btn>
                         <v-btn
                            color="danger"
                            text
                             @click="openEliminar = false"
                        >
                            No
                        </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

      
    </div>
        
</template>

<script>
import { fdcService } from '../../fdc/dispatcher'

import Header from "../../components/header/HeaderThree";
import Footer from "../../components/footer/FooterTwo";
import Publicidad from "../../components/fdc/Publicidad"; 
import EdicionVehiculo from "../../components/fdc/EdicionVehiculo";
import EditarCaracteristicas from "../../components/fdc/EditarCaracteristicas";  
import EdicionImagenes from "../../components/fdc/EdicionImagenes";  
import PasoEdicion from "../../components/fdc/PasoEdicion";  
import PasoCaracteristicas from "../../components/fdc/PasoCaracteristicas"; 
import PasoImagenes from "../../components/fdc/PasoImagenes";  
import Publicar from "../../components/fdc/Publicar";  
import Mantencion from "../../components/fdc/Mantenciones";  



export default {

    components:{
        Header,
        Footer,
        Publicidad, 
        EdicionVehiculo,
        EditarCaracteristicas,
        EdicionImagenes,
        PasoEdicion,
        PasoCaracteristicas,
        PasoImagenes,
        Publicar,
        Mantencion
    },
    data(){
        return {
            vehiculos:[],
            publicidad: {},
            mostrarPublicidad: false,
            vehiculoInt: null,
            showPasos: false,
            paso:1,
            openEditar: false,
            openEditarCarac: false,
            openEditarImg: false,
            openPublicar: false,
            openEliminar: false,
            openMantencion: false,
            vehiculoEditar: null
        }
    },
    mounted(){
        this.cargarVehiculos()
        if(this.$route.params.new == "new") {
                  this.desdePublicar()
                }
    },
    methods:{
        cargarVehiculos(){
            fdcService.execute("mis-vehiculos").then( r => {
                this.vehiculos = r.vehiculos
                this.publicidad = r.publicidad
                this.mostrarPublicidad = true  
                
            })
        },
        formatoPesosChilenos(valor){
            const formatterPeso = new Intl.NumberFormat('es-CL', {
            style: 'currency',
            currency: 'CLP',
            minimumFractionDigits: 0
        })

            return formatterPeso.format(valor)

        },
         abrirPublicacion(id){
            
            this.$router.push({path:'automotriz/publicacion/' + id})
        },
         abrirVehiculo(id){
            
            this.$router.push({path:'automotriz/vehiculo/' + id})
        },
        accionPaso(value){
            console.log("value", value)
            this.vehiculoEditar = value.id
            this.paso ++
            if(value.recarga) this.cargarVehiculos()
            if(this.paso > 4){
                this.showPasos = false
                this.paso = 1
                
            }
        },
        eliminarVehiculo(){
             fdcService.execute("eliminar-vehiculo", [this.vehiculoEditar]).then( r => {
               if(r.resultado == "ok"){
                this.cargarVehiculos()
                this.openEliminar = false
               }
            })
        },
        desdePublicar(){
            this.paso = 1
            this.vehiculoEditar = null
            this.showPasos = true
        },
        cerrarPaso(){
            this.showPasos = false
            this.paso = 1
            this.cargarVehiculos()
        }
    },
    
}
</script>

<style>
.footer{
    width: 100%;
}
.icono{
    font-size: 0.3em;
}


</style>