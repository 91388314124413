<template>
    
  
    <div>
    
     
    
        
            <v-container class=" pt-10  pb-10 ">
              <v-row>
                <v-col cols="12"  sm="6" md="4"  lg="4" xl="3">
                    <v-autocomplete
                        :items="itemsColor"
                        label="Color"
                        item-text="descripcion"
                        item-value="idsubtipo"
                        v-model="guardarCaracteristicas.caracteristicas.idcolor"
                         
                    >
                    </v-autocomplete>
                </v-col>
                <v-col  cols="12"  sm="6" md="4"  lg="4" xl="3">
                    <v-autocomplete
                        :items="itemsTransmision"
                        label="Transmision"
                        item-text="descripcion"
                        item-value="idsubtipo"
                        v-model="guardarCaracteristicas.caracteristicas.idtransmision"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col  cols="12"  sm="6" md="4"  lg="4" xl="3">
                    <v-autocomplete
                       :items="itemsPuertas"
                        label="Puertas"
                        item-text="descripcion"
                        item-value="idsubtipo"
                        v-model="guardarCaracteristicas.caracteristicas.idpuertas"
                    >
                    </v-autocomplete>
                </v-col>
                 <v-col  cols="12"  sm="6" md="4"  lg="4" xl="3">
                    <v-autocomplete
                       :items="itemsCombustible"
                        label="Combustible"
                        item-text="descripcion"
                        item-value="idsubtipo"
                        v-model="guardarCaracteristicas.caracteristicas.idcombustible"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col  cols="12"  sm="6" md="4"  lg="4" xl="3">
                    <v-autocomplete
                       :items="itemsDireccion"
                        label="Direccion"
                        item-text="descripcion"
                        item-value="idsubtipo"
                        v-model="guardarCaracteristicas.caracteristicas.iddireccion"
                    >
                    </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-card-title>
                    Detalles
                </v-card-title>
                <v-card-text class="m-0 p-0">
                    <v-row class="m-0 p-0 loscheck">
                    <v-col v-for="linea in guardarCaracteristicas.equipamiento" :key="linea.label" cols="6"  sm="4" md="4"  lg="3" xl="3" class="m-0 p-0">
                            <v-checkbox class="m-0 p-0"  v-model="linea.checked"  :label="linea.descripcion"></v-checkbox>
                    </v-col>
                </v-row>
                </v-card-text>
              </v-row>
              <v-row class="justify-center mt-5">
                 <v-col  cols="12"  sm="8" md="6"  lg="4" xl="4" class="mt-1">
                        <v-btn class="red " block @click="salir()">
                            Cerrar
                        </v-btn>
                  </v-col> 

                <v-col  cols="12"  sm="8" md="6"  lg="4" xl="4" class="mt-1">
                    <v-btn class="blue red-text" block @click="guardar()">
                        Siguiente
                    </v-btn>
                </v-col> 
                 

              </v-row>
               
          </v-container>
          

    

      <v-dialog v-model="dialogExito" width="500" >
        <v-card >
        <v-card-text class="text-h6 grey lighten-2">
          Caracteristicas guardadas correctamente
        </v-card-text>

        

      

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogExito = false , show = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>

      <v-dialog v-model="dialogFracaso" width="500" >
        <v-card >
        <v-card-title class="text-h5 grey lighten-2">
          Error al guardar
        </v-card-title>

        <v-card-text class="notificacion">
          Verifique los datos.
        </v-card-text>

      

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogFracaso = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>
     

    </div>
  
</template>


<script>
import { fdcService } from '../../fdc/dispatcher'
import {api} from '../../fdc/api'
export default {


    props: ["value","id",],
    computed: {
    show: {
        get () {
        return this.value
        },
        set (value) {
         this.$emit('input', value)
      }
    },
   
   
  }, data(){
        return{
            dialogExito: false,
            dialogFracaso: false,
            mensajeError: false,
            mensajeExito: false,
            itemsColor: [],
            itemsTransmision: [],
            itemsPuertas: [],
            itemsCombustible: [],
            itemsDireccion: [],
            guardarCaracteristicas: {
                id: "",
                equipamiento: [],
                caracteristicas: {
                    idcolor: 0,
                    idcombustible: 0,
                    iddireccion: 0,
                    idpuertas: 0,
                    idtransmision: 0,
                }
            },
           

        }

    },
     created(){
        this.cargarInformacion();
        
    },
    

    methods: {
        cargarInformacion(){
            let idvehiculo = this.id
           
            if (this.id == null) idvehiculo = 0
            
            
            fdcService.execute('editar', [idvehiculo]).then( r => {
            if (idvehiculo != 0){
                this.guardarCaracteristicas.caracteristicas = r.caracteristicas;
                this.guardarCaracteristicas.id = r.vehiculo.id
                
            } 
            this.guardarCaracteristicas.equipamiento = r.equipamiento
            this.itemsColor = r.colores
            this.itemsTransmision = r.transmisiones
            this.itemsPuertas = r.puertas
            this.itemsCombustible = r.combustibles
            this.itemsDireccion = r.direcciones
             
             
              
            })
        },
        cargarCiudades(){
            this.itemsCiudades = this.todasLasCiudades.filter( ciudad =>ciudad.idregion == this.regionSeleccionada)
        },
        guardar(){
            fdcService.execute("caracteristicas", this.guardarCaracteristicas).then( r => {
               if (r.resultado == "ok"){
                this.$emit("accion", {id: this.id})
                console.log("El id que se manda", this.id)
              } 
              else {
                this.dialogFracaso = true
                this.dialogExito = false
              }
            }).catch(error => {
              this.dialogFracaso = true
              this.dialogExito = false
            })
        } ,
        salir(){
          this.$emit("cerrarPaso")
        }
    }
}
</script>

<style>
.notificacion {
  font-size: 1.2em;
  color:black !important;
}
</style>