import { api } from './api';
import axios from 'axios';

export const fdcService = {
    execute,
    getQuery
}

async function getQuery(service, query, headers = {})    {
    const servicio = api.getRuta(service);
    const servicioParth = servicio.path + "?" + query;
    return new Promise( (resolve, reject) => {
        axios.get(servicioParth, api.getHeader(servicio, headers)).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject({ resultado: "error", mensaje: "No fue posible obtener respuesta", response: error.response });
        })
    });
}

async function execute(service, request = null, headers = {}) {
    const servicio = api.getRuta(service);
    let servicioPath = api.getHost(servicio.path);
    if (servicio.method != "post" && request !== undefined && request !== null) servicioPath += "/" + request.join("/")
    switch (servicio.method) {
        case "get":
            return new Promise( (resolve, reject) => {
                axios.get(servicioPath, api.getHeader(servicio, headers)).then((response) => {
                    resolve(response.data);
                }).catch((error) => {
                    const resultado = { resultado: "error", mensaje: "No fue posible obtener respuesta", response: error.response, path: servicioPath, request: request, method: "post" };
                    reject(resultado);
                })
            })
        case "post":
            return new Promise( (resolve, reject) => {
                axios.post(servicioPath, request, api.getHeader(servicio, headers)).then((response) => {
                    resolve(response.data);
                }).catch((error) => {
                    const resultado = { resultado: "error", mensaje: "No fue posible obtener respuesta", response: error.response, path: servicioPath, request: request, method: "post" };
                    reject(resultado);
                })
            })
        case "delete":
            return new Promise( (resolve, reject) => {
                axios.delete(servicioPath, api.getHeader(servicio, headers)).then((response) => {
                    resolve(response.data);
                }).catch((error) => {
                    const resultado = { resultado: "error", mensaje: "No fue posible obtener respuesta", response: error.response, path: servicioPath, request: request, method: "post" };
                    reject(resultado);
                })
            })
        case "put":
            return new Promise( (resolve, reject) => {
                axios.put(servicioPath, request, api.getHeader(servicio, headers)).then((response) => {
                    resolve(response.data);
                }).catch((error) => {
                    const resultado = { resultado: "error", mensaje: "No fue posible obtener respuesta", response: error.response, path: servicioPath, request: request, method: "post" };
                    reject(resultado);
                })
            })
        case "patch":
            return new Promise( (resolve, reject) => {
                axios.patch(servicioPath, request, api.getHeader(servicio, headers)).then((response) => {
                    resolve(response.data);
                }).catch((error) => {
                    const resultado = { resultado: "error", mensaje: "No fue posible obtener respuesta", response: error.response, path: servicioPath, request: request, method: "post" };
                    reject(resultado);
                })
            })
        case "options":
            return new Promise( (resolve, reject) => {
                axios.options(servicioPath, api.getHeader(servicio, headers)).then((response) => {
                    resolve(response.data);
                }).catch((error) => {
                    const resultado = { resultado: "error", mensaje: "No fue posible obtener respuesta", response: error.response, path: servicioPath, request: request, method: "post" };
                    reject(resultado);
                })
            })
    }

}