<template >
    
    <div v-if="publicidad.length > 0"  :class="{fija: fija}">
        <v-row >
            <v-col cols="12" sm="12"  >
                <div   >
                    <Carousel
                   
                    :speed=10000
                    autoplay
                    :items-per-view=1
                    :autoplay-timeout=5000
                    :enable-buttons="false"
                    >

                            <v-img v-for="e in extendidos" 
                            :key="e.idpublicidad" 
                            @click="ir(e.url)" 
                            :src="e.imagen" 
                            :aspect-ratio="16/9" contain
                            
                            
                            > </v-img>    
                    </Carousel> 
                </div>
                    

                            <!--  <v-carousel cycle hide-delimiters :height="maxHeight" >
                            <v-carousel-item  v-for="e in extendidos" :key="e.idpublicidad" width="400"> 
                                <v-img @click="ir(e.url)" :src="e.imagen" > </v-img> 
                            </v-carousel-item>
                            </v-carousel> -->
                    
                        
            </v-col>
        </v-row>
        <v-row>  
            <v-col  cols="12">
                 <div >
                    <Carousel
                :speed=10000
                autoplay
                :items-per-view=2
                :autoplay-timeout=5000
                :enable-buttons="false"
            
                >
                    <v-img  v-for="p in pequeños" :key="p.idpublicidad" @click="ir(p.url)" :src="p.imagen" :aspect-ratio="16/12" contain> 
                    </v-img>    
                </Carousel> 
                </div> 
            
              
                <v-carousel cycle hide-delimiters :height="maxHeight" >
                    <v-slide-group
                        multiple
                        show-arrows
                        >
                        <v-slide-item
                            v-for="n in 25"
                            :key="n"
                            v-slot="{ active, toggle }"
                        >
                            <v-btn
                            class="mx-2"
                            :input-value="active"
                            active-class="purple white--text"
                            depressed
                            rounded
                            @click="toggle"
                            >
                            Options {{ n }}
                            </v-btn>
                        </v-slide-item>
                        </v-slide-group>
                    <v-carousel-item  v-for="p in pequeños" :key="p.idpublicidad" width="400">

                        <v-img @click="ir(p.url)" :src="p.imagen" :aspect-ratio="16/5" contain > </v-img> 
                    </v-carousel-item>
                </v-carousel>
            </v-col>
        </v-row>
    </div>
            
  
</template>

<script>
import Carousel from "vue2-simple-carousel";

export default {
    components:{
        Carousel
    },
    
    props: ["publicidad"],
    data(){
        return {
            extendidos: [],
            pequeños: [],
            maxHeight: 0,
            fija: false,
        }
        
    },
    computed: {
      height () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 'xs'
          case 'sm': return 'sm'
          case 'md': return 'md'
          case 'lg': return 'lg'
          case 'xl': return 'xl' 
        }
      },
    },
     created(){
        this.onResize()
    },
    mounted(){
        this.publicidad.forEach(element => {
            if(element.idtipopublicidad !== 2){
                this.extendidos.push(element)
            }
            else {
                this.pequeños.push(element)
            }
        });

        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
         })
        
    },
     beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
    },
    methods: {
         onResize() {
            console.log("height", this.height)
      /*       this.ventana = window.innerWidth
            
            if (this.ventana < 600)  this.fija = false;
            if (this.ventana >= 600 && this.ventana < 960)   this.fija = false;
            if (this.ventana >= 960 && this.ventana < 1264)   this.fija = false;
            if (this.ventana >= 1264 && this.ventana < 1904)   this.fija = true;
            if (this.ventana >= 1904)  this.fija = true;

           
             */
        },
        ir(url){
            window.open(url, '_blank');
        }
    }
}
</script>

<style>
.fija {
     position:fixed;  height: 50px; width: 420px; margin-bottom: 50px; 
}


</style>