<template>
    <v-dialog
      v-model="show"
    >
        <v-card>

           

            <LazyYoutubeVideo v-if="show  == true" :src="url" />
            
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="show = false"
          >
            Cerrar
          </v-btn>
         
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import Vue from 'vue'
import LazyYoutubeVideo from 'vue-lazy-youtube-video'
import 'vue-lazy-youtube-video/dist/style.css'

Vue.component('LazyYoutubeVideo', LazyYoutubeVideo)
export default {
    props: {
        value: Boolean,
        url: String
    },
   
    
    computed: {
    show: {
        get () {
        return this.value
        },
        set (value) {
         this.$emit('input', value)
      }
    },
   
   
  },
  
}
</script>

<style>

</style>