<template>
  <div id="app">
    <vue-dropzone
      ref="imgDropZone"
      id="customdropzone"
      :options="dropzoneOptions"
      @vdropzone-complete="afterComplete"
    ></vue-dropzone>
    <v-row v-if="images.length > 0"  class="pt-10 pb-10">
      <v-col v-for="image in images" :key="image.url" cols="12"  sm="6" md="4" lg="4" xl="4" class=" pb-10">
        <v-img  :src="image.url" :aspect-ratio="16/9" contain>
          <v-icon @click="eliminar(image.id)" class=" red--text" large>mdi-close-box</v-icon>
        </v-img>
      </v-col>
    </v-row>
    <v-row v-else class="pt-10 pb-10">
      <v-col cols="12" class="text-center">
        <h3 >No hay imagenes para mostrar</h3>
      </v-col>

    </v-row>
    <v-row class="justify-center mt-5">
       <v-col  cols="12"  sm="8" md="6"  lg="4" xl="4" class="mt-1">
        <v-btn class="red " block @click="salir()">
            Cerrar
        </v-btn>
      </v-col> 
      <v-col  cols="12"  sm="8" md="6"  lg="4" xl="4" class="mt-1">
        <v-btn class="blue red-text" block @click="guardar()">
          Siguiente
        </v-btn>
      </v-col> 
     
    </v-row>

    <v-dialog v-model="dialogExito" width="500" >
        <v-card >
        <v-card-text class="text-h6 grey lighten-2">
          Vehículo guardado correctamente
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogExito = false , show = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>

      <v-dialog v-model="dialogFracaso" width="500" >
        <v-card >
        <v-card-title class="text-h5 grey lighten-2">
          Error al guardar
        </v-card-title>

        <v-card-text class="notificacion">
          Verifique los datos.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogFracaso = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>

  </div>
</template>

<script>
import axios from 'axios';

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { fdcService } from '../../fdc/dispatcher';
import { api } from '../../fdc/api';

export default {
  components: {
    vueDropzone: vue2Dropzone
  },
  props:["id"],
  data() {
    return {
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        addRemoveLinks: false,
        parallelUploads: 3,
        resizeWidth: 1024,
        maxFiles: 3,
        acceptedFiles: ".jpg, .jpeg, .png",
        dictDefaultMessage: `<p class='text-default'><i class='fa fa-cloud-upload mr-2'></i> Arrastrar las imagenes o hacer click para subir</p>
          <p class="form-text"> Tipos aceptados: .jpg, .jpeg, .png</p>
          `
      },
      images: [],
      dialogExito: false,
      dialogFracaso: false,
    };
  },
  watch: {
    id(newId, oldId){
      if (newId !== oldId){
        this.cargarInformacion()
      }
      
    }
  },

  created(){
    this.cargarInformacion()
  },
  methods: {

    cargarInformacion(){
      let idvehiculo = this.id
      if (this.id == null){
        idvehiculo = 0
      } 
      fdcService.execute('editar', [idvehiculo]).then( r => {
        this.images = r.imagenes
      })
    },

    guardar(){
       this.$emit("accion", {id: this.id})
    },

    eliminar(imagen){
      fdcService.execute("imagenes-borrar", [this.id, imagen]).then(r => {
          this.images = this.images.filter( img =>img.id !== imagen)
      })
    },

    async afterComplete(upload) {
     
      this.isLoading = true;
     
      let formData = new FormData()
      const _file = upload
      formData.append('files', _file)

      axios({
        method: "post",
        // url: "https://pc3.fabricadecodigo.dev:8084/automotriz/misvehiculos/imagenes/subir/" +  this.id,
        url: api.getHost("/automotriz/misvehiculos/imagenes/subir/" +  this.id),
        data: formData,
        headers: { "Content-Type": "multipart/form-data", "Authorization": "Bearer " + api.getUsuario().token },
      }).then( r => {
          this.images.push(r.data.imagenes[0])
          this.$refs.imgDropZone.removeAllFiles()
          
      })

      /* fdcService.execute("imagenes-subir",formData, this.id ).then(r => {
        console.log("Resultado subida", r)
      }) */
     
      
     
    },
    salir(){
          this.$emit("cerrarPaso")
        }
  }
};
</script>

<style>
.image-div {
  display: flex;
  margin: 25px;
}
.image {
  max-width: 250px;
  margin: 15px;
}
 .text-default, .form-text{
  color:black!important;
}
</style>