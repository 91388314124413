<template>
    <div  >        
            <v-container>
            <v-row>
                <v-col  cols="12"  sm="6" md="6"  lg="4" xl="4" class="mt-10"  
                v-if=" publicacion.publicacion_idsubtipo == 201 || publicacion.publicacion_idsubtipo == 205">
                    <v-autocomplete
                       
                        :items="itemsPubPublicacion"
                        label="Situación de la publicación"
                        item-text="texto"
                        item-value="publicacion_idsubtipo"
                        v-model="publicacion.publicacion_idsubtipo"
                        readonly
                           
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12"  sm="6" md="6"  lg="4" xl="4" class="mt-10">
                    <v-text-field
                        label="Título de la publicación"
                        counter="100"
                        v-model="publicacion.titulo"
                        required
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12"  sm="6" md="6"  lg="4" xl="4" class="mt-10">
                    <v-text-field
                        label="Precio"
                        v-model="publicacion.precio"
                        counter="9"
                        required
                        type="number"
                    >
                    </v-text-field>
                </v-col> 

                 <v-col  cols="12"  sm="6" md="6"  lg="4" xl="4" class="mt-10">
                    <v-autocomplete
                        :items="itemsEstados"
                        label="Tipo de publicación"
                        item-text="texto"
                        item-value="estado_idsubtipo"
                        v-model="publicacion.estado_idsubtipo"
                    >
                    </v-autocomplete>
                </v-col> 
                
                <v-col cols="12"  sm="6" md="6"  lg="4" xl="4" class="mt-10">
                    <v-autocomplete
                        :items="itemsRegiones"
                        label="Región"
                        item-text="nombre"
                        item-value="idregion"
                        v-model="regionSeleccionada"
                        @change="cargarCiudades()"
                           
                    >
                    </v-autocomplete>
                </v-col>
                 <v-col cols="12"  sm="6" md="6"  lg="4" xl="4" class="mt-10">
                    <v-autocomplete
                        :items="itemsCiudades"
                        label="Ciudad donde se publicará"
                        item-text="nombre"
                        item-value="idciudad"
                        v-model="publicacion.idciudad"
                           
                    >
                    </v-autocomplete>
                </v-col>
               

                <v-col cols="12"  sm="12" md="8"  lg="8" xl="8" class="mt-10">
                    <v-textarea
                        label="Descripción"
                        v-model="publicacion.descripcion"
                        counter="1000"
                        required
                        outlined
                    >
                    </v-textarea>
                </v-col> 
                
               
            
            </v-row> 
            <v-row class="justify-center mt-5">
                 <v-col  cols="12"  sm="8" md="6"  lg="4" xl="4" class="mt-1">
                        <v-btn class="red " block @click="salir()">
                            Cerrar
                        </v-btn>
                    </v-col> 
                  <v-col  cols="12"  sm="8" md="6"  lg="4" xl="4" class="mt-1">
                    <v-btn class="blue red-text" block @click="guardar()">
                        Publicar
                    </v-btn>
                </v-col> 
                 
              </v-row>
            </v-container>
  
        <v-dialog v-model="dialogExito" width="500" >
            <v-card >
                <v-card-text class="text-h6 grey lighten-2">
                Caracteristicas guardadas correctamente
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            text
                            @click="dialogExito = false , show = false"
                        >
                            Aceptar
                        </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogFracaso" width="500" >
            <v-card >
                <v-card-title class="text-h5 grey lighten-2">
                Error al guardar
                </v-card-title>
                <v-card-text class="notificacion">
                Verifique los datos.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="dialogFracaso = false"
                    >
                        Aceptar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
  
</template>


<script>
import { fdcService } from '../../fdc/dispatcher'
import {api} from '../../fdc/api'
import moment from "moment"
export default {


    props: ["value","id",],
    computed: {
    show: {
        get () {
        return this.value
        },
        set (value) {
         this.$emit('input', value)
      }
    },
   
   
  }, 
  watch: {
    id(newId, oldId){
      if (newId !== oldId){
        this.cargarInformacion()
      }
    }
  },
  data(){
        return{
            dialogExito: false,
            dialogFracaso: false,
            mensajeError: false,
            mensajeExito: false,
            itemsPubPublicacion: [
                {
                    publicacion_idsubtipo: 201,
                    texto: "En revision"
                },
                {
                    publicacion_idsubtipo: 202,
                    texto: "Publicado"
                },
                {
                    publicacion_idsubtipo: 204,
                    texto: "No publicado"
                },
                {
                    publicacion_idsubtipo: 205,
                    texto: "Rechazado"
                },
            ],
             itemsEstados: [
                {
                    estado_idsubtipo: 103,
                    texto: "En venta"
                },
                 {
                    estado_idsubtipo: 102,
                    texto: "Privado"
                },
                {
                    estado_idsubtipo: 101,
                    texto: "Público"
                },
               
            ],
            itemsRegiones: [],
            itemsCiudades: [],
            todasLasCiudades: [],
            itemsDireccion: [],
            regionSeleccionada: null,
            publicacion: {
                idpublicacion: null,
                idvehiculo: 0,
                titulo: "",
                precio: 0,
                idmoneda: 0,
                descripcion: "",
                fechapublicacion: moment().format(),
                fechacreacion: moment().format(),
                fechatermino: null,
                idciudad: 0,
                estado_idsubtipo: 102,
                publicacion_idsubtipo: null,
                id: ""
            }
        }

    },
     created(){
        this.cargarUbicaciones();
        this.cargarInformacion();
        
    },
    

    methods: {
        cargarInformacion(){
            let idvehiculo = this.id
            console.log("El id", this.id)
            if (this.id == null)  idvehiculo = 0
            
            
            fdcService.execute('editar', [idvehiculo]).then( r => {
                if (idvehiculo != 0){
                    if (r.publicacion != null) {
                        this.publicacion = r.publicacion
                        let laciudad = this.todasLasCiudades.filter( c  => c.idciudad == r.publicacion.idciudad)
                        console.log(laciudad)
                        this.regionSeleccionada = laciudad[0].idregion
                        this.cargarCiudades()
                        
                    }
                    else {
                        this.publicacion = {
                            idpublicacion: null,
                            idvehiculo: 0,
                            titulo: "",
                            precio: 0,
                            idmoneda: 0,
                            descripcion: "",
                            fechapublicacion: moment().format(),
                            fechacreacion: moment().format(),
                            fechatermino: null,
                            idciudad: 0,
                            estado_idsubtipo: 102,
                            publicacion_idsubtipo: null,
                            id: ""
                        }
                    }
                } 
            })
        },
        cargarUbicaciones(){
            fdcService.execute('ubicaciones').then( r => {
                this.itemsRegiones = r.regiones
                this.todasLasCiudades = r.ciudades
            })
        },
        cargarCiudades(){
            this.itemsCiudades = this.todasLasCiudades.filter( ciudad => ciudad.idregion == this.regionSeleccionada)
        },
        guardar(){
            let guardarPublicacion = {id_vehiculo: this.id, publicacion: this.publicacion }
            fdcService.execute("publicar", guardarPublicacion).then( r => {
               if (r.resultado == "ok"){
                this.$emit("accion", {recarga: true})
                this.show = false;
                
              } 
              else {
                this.dialogFracaso = true
                this.dialogExito = false
              }
            }).catch(error => {
              this.dialogFracaso = true
              this.dialogExito = false
            })
        },
        salir(){
          this.$emit("cerrarPaso")
        }
    }
}
</script>

<style>
.notificacion {
  font-size: 1.2em;
  color:black !important;
}
.situacion v-label input {
    background-color: orange!important;
}
</style>