<template>
    <div>
        <Header >
            <img slot="logo" src="../../assets/images/fdc/Logo.png" >
        </Header>

        <v-row  v-if="maxItems != 1" class="mt-10 pt-6 mb-5">
            <v-col>
                 <Buscar 
                    :itemsRegion="itemsRegion" 
                    :itemsTipo="itemsTipo"
                    :itemsMarca="itemsMarca"
                    :itemsMin="itemsMin"
                    :itemsMax="itemsMax"
                    :todosLosPrecios="todosLosPrecios"
                    :todosLosAnos="todosLosAnos"
                    :itemsAnoDesde="itemsAnoDesde"
                    @buscar="buscar"

                   
                >  
                </Buscar>
            </v-col>
        </v-row>
       
        
        <v-expansion-panels class="mt-10 pt-6 mb-5" v-else>
            <v-expansion-panel>
                <v-expansion-panel-header  expand-icon="mdi-magnify" disable-icon-rotate>
                    <template >
                        <v-row no-gutters>
                           Busqueda
                        </v-row>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <Buscar 
                            :itemsRegion="itemsRegion" 
                            :itemsTipo="itemsTipo"
                            :itemsMarca="itemsMarca"
                            :itemsMin="itemsMin"
                            :itemsMax="itemsMax"
                            :todosLosPrecios="todosLosPrecios"
                            :todosLosAnos="todosLosAnos"
                            :itemsAnoDesde="itemsAnoDesde"
                            @buscar="buscar"
                    >  
                    </Buscar>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

       

    <!--  Comienzo de vista web  -->
       
       
        <v-row v-if="busquedaActiva == true" class="pl-5 pr-5">
            <v-col cols="12" sm="12" md="12" lg="8" xl="8" >
                <h3>Resultados de la búsqueda</h3>
                <v-row v-if="encontrados.length > 0">
                    <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4"  v-for="encontrado in encontrados" :key="encontrado.idpublicacion">
                        <v-card class="card mb-4" @click="abrirPublicacion(encontrado.idpublicacion)">
                            <v-img  :src="encontrado.vehiculo.media"></v-img>
                            
                            <v-card-text>
                                <h5 class="titulo">{{encontrado.titulo}}</h5>
                                <span>{{ encontrado.vehiculo.marca }} {{ encontrado.vehiculo.modelo }} - {{ encontrado.vehiculo.ano }}</span>
                                <h6 >{{formatoPesosChilenos(encontrado.precio)}}</h6>
                                <h6 class="titulo"  v-if="encontrado.descripcion < 50"> {{encontrado.descripcion.substring(0,8)+".." }}</h6>
                                <h6 class="titulo" v-else> {{encontrado.descripcion.substring(0,50)+ ".." }}</h6>
                            </v-card-text>   
                        </v-card>
                    </v-col>
                </v-row>  
                <v-row v-else class="mt-10 mb-10">
                    <v-col>
                        <v-card >
                            <v-card-text class="text-center">
                                No se han encontrado resultados para la busqueda
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="4" xl="4" >
                <Publicidad :publicidad="this.publicidad" v-if="mostrarPublicidad" ></Publicidad>
            </v-col>
        </v-row>
        <v-row v-if="busquedaActiva == false" class="pl-5 pr-5 pb-5 mb-5 mt-5">
            <v-col cols="12" sm="12" md="12" lg="8" xl="8" fluid >

                <v-row  v-if="destacados.length > 0">
                    <v-col cols="12">
                    <h3>Destacados</h3>

                    <Carousel
                        :speed=10000
                        autoplay
                        :autoplay-timeout=5000 
                        :items-per-view=maxItems
                        :auto-height="false"
                    >
                    
                        <v-card
                            v-for="destacado in destacados" :key="destacado.idpublicacion"
                        class="card"
                        @click="abrirPublicacion(destacado.idpublicacion)"
                        
                        >
                            <v-img :aspect-ratio="16/9" contain :src="destacado.vehiculo.media"></v-img>
                            
                            <v-card-text>
                                <h5 class="titulo" v-if="destacado.titulo < 25">{{destacado.titulo}}</h5>
                                <h5 class="titulo"  v-else>{{destacado.titulo.substring(0,25)+ ".." }}</h5>
                                <span>{{ destacado.vehiculo.marca }} {{ destacado.vehiculo.modelo }} - {{ destacado.vehiculo.ano }}</span>
                                <h6 >{{formatoPesosChilenos(destacado.precio)}}</h6>

                                <h6 class="titulo"  v-if="destacado.descripcion < 50">  {{destacado.descripcion.substring(0,8)+".." }}</h6>
                                <h6 class="titulo" v-else> {{destacado.descripcion.substring(0,50)+ ".." }}</h6>
                            
                            </v-card-text>   
                        </v-card>
                    
                    </Carousel>
                    </v-col>
                </v-row>  
            
                <br>
            

                <v-row fluid   v-if="enVenta.length > 0">
                    <v-col cols="12">
                        <h3>En venta</h3>

                        <Carousel
                            :speed=10000
                            autoplay
                            :autoplay-timeout=5000 
                            :items-per-view=maxItems
                            :auto-height="false"
                        >
                        
                        <v-card
                            v-for="ev in enVenta" :key="ev.idpublicacion"
                            class="card"
                            @click="abrirPublicacion(ev.idpublicacion)"
                        >
                            <v-img  :src="ev.vehiculo.media" :aspect-ratio="16/9" contain></v-img>
                            
                            <v-card-text>
                                <h5 class="titulo" v-if="ev.titulo < 25">{{ev.titulo}}</h5>
                                <h5 class="titulo"  v-else>{{ev.titulo.substring(0,25)+ ".." }}</h5>
                                <span>{{ ev.vehiculo.marca }} {{ ev.vehiculo.modelo }} - {{ ev.vehiculo.ano }}</span>
                                <h6 >{{formatoPesosChilenos(ev.precio)}}</h6>

                                <h6 class="titulo"  v-if="ev.descripcion < 50"> {{ev.descripcion.substring(0,8)+".." }}</h6>
                                <h6 class="titulo" v-else> {{ev.descripcion.substring(0,50)+ ".." }}</h6>
                            
                            </v-card-text>   
                        </v-card>
                        
                        </Carousel>
                    </v-col>
                </v-row>

            
            
                <br>
        

                <v-row  fluid   v-if="destacados.length > 0">
                    <v-col cols="12">
                        <h3>Ultimos registrados</h3>

                <Carousel
                    :speed=10000
                    autoplay
                    :autoplay-timeout=5000 
                    :items-per-view=maxItems
                    :auto-height:="false"
                >
                
                <v-card
                    v-for="ultimo in ultimos" :key="ultimo.idpublicacion"
                    class="card"
                    @click="abrirPublicacion(ultimo.idpublicacion)"
                >
                    <v-img :aspect-ratio="16/9" contain  :src="ultimo.vehiculo.media"></v-img>
                    
                    <v-card-text>
                        <h5 class="titulo" v-if="ultimo.titulo < 25">{{ultimo.titulo}}</h5>
                        <h5 class="titulo"  v-else>{{ultimo.titulo.substring(0,25)+ ".." }}</h5>
                        <span>{{ ultimo.vehiculo.marca }} {{ ultimo.vehiculo.modelo }} - {{ ultimo.vehiculo.ano }}</span>
                        <h6 >{{formatoPesosChilenos(ultimo.precio)}}</h6>

                        <h6 class="titulo"  v-if="ultimo.descripcion < 50"> {{ultimo.descripcion.substring(0,8)+".." }}</h6>
                        <h6 class="titulo" v-else> {{ultimo.descripcion.substring(0,50)+ ".." }}</h6>
                        
                    </v-card-text>   
                </v-card>
                
                </Carousel>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="4" xl="4" class="pl-5 pr-5 pb-5 mb-5 mt-5 " >
                <Publicidad :publicidad="this.publicidad" v-if="mostrarPublicidad" ></Publicidad>
            </v-col>
        </v-row>
        
        
        
        <br>
        <v-container class="parrafos" fluid>
             <br>
            <v-row class="pr-5 pl-5">
                
                <v-col cols="12" sm="12" md="12" lg="4" xl="4" class="pb-5 pt-5">
                    <h4>Registre su vehículo</h4>
                    <p>
                        Si tiene automóvil o vehículo regístrelo en "PrecioConversable - Portal Automotriz", 
                        puede buscar y seleccionar el vehículo de su interés o preferencia, contactar al vendedor y hacer su oferta. 
                        También puede registrar su nuevo o actual vehículo en estado “Privado”, registrar sus mantenciones (fecha, kilómetros, facturas y OTs). 
                        Cuando decida venderlo sólo debe cambiar el estado a “En Venta” y su vehículo será visualizado públicamente. 
                        Una vez vendido sólo infórmenos que el vehículo tiene un nuevo dueño y pasará nuevamente a estar registrado en “Privado” con todo su historial.
                    </p>
                </v-col>
                 <v-col cols="12" sm="12" md="12" lg="4" xl="4" class="pb-5 pt-5">
                    <h4>Planifique su compra</h4>
                    <p>
                        Si aún no tiene vehículo o quiere cambiarlo planifique su compra, 
                        cree una lista de preferencias en su panel de control (marcas, modelos, valores y fechas), 
                        será notificado a su correo con posibilidades a partir de las fechas que indique, 
                        será el primero en ser informado de la venta de vehículos con las características de su interés.
                    </p>
                </v-col>
                 <v-col cols="12" sm="12" md="12" lg="4" xl="4" class="pb-5 pt-5">
                      <h4>Contacto</h4>
                    <p>
                        Villanelo 180, Of. 1405 <br>
                        Viña del Mar, Región de Valparaíso, Chile <br>
                        <a  href="tel:+569 9831 7127"> Móvil: +569 9831 7127 </a><br>
                        <a target="_blank" href="https://wa.me/+56998317127">WhatsApp: +569 9831 7127</a> <br>
                        <a  href="mailto:contacto@precioconversable.com">Email:contacto@precioconversable.com</a> <br>
                    </p>
                </v-col>
                
            </v-row>
            <br>
        </v-container>
        
        <Footer></Footer>
         
        
    </div>
</template>

<script>
import { fdcService } from '../../fdc/dispatcher'

import Header from "../../components/header/HeaderThree";
import Footer from "../../components/footer/FooterTwo";
import Publicidad from "../../components/fdc/Publicidad";
import Buscar from "../../components/fdc/Buscar";
import Carousel from "vue2-simple-carousel";

import Vue from 'vue';




export default {

    components:{
        Header,
        Footer,
        Publicidad,
        Carousel,
        Buscar
      
        
    },

    data() {
        return{
            landing: null,
             data: [
                '<div class="example-slide">Slide 1</div>',
                '<div class="example-slide">Slide 2</div>',
                '<div class="example-slide">Slide 3</div>',
            ],
            itemsRegion: null,
            itemsTipo: null,
            itemsMarca: null,
            itemsMV: null,
            itemsAnoDesde: null,
            itemsAnoHasta: null,
            itemsMin: null,
            itemsMax: null,
            destacados: [],
            enVenta: [],
            ultimos: [],
            marcaSeleccionada: null,
            desdeSeleccionado: null,
            todosLosPrecios: [],
            todosLosAnos: [],
            busquedaActiva: false,
            encontrados: [],
            publicidad: [],
            mostrarPublicidad: false,
            ventana: window.innerWidth,
            maxItems: 1,
            
            


        }
    },
    created(){
        this.onResize()
    },

    mounted(){
        this.traerLanding()
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
         })
        
    },
    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
    },
    methods: {
        onResize() {
            this.ventana = window.innerWidth
            if (this.ventana > 960) this.maxItems = 4;
            else this.maxItems = 1;
        },
        traerLanding(){
            fdcService.execute("landing").then(r => {
               

                this.itemsRegion = r.buscador.regiones
                this.itemsTipo = r.buscador.tipos
                this.itemsMarca = r.buscador.marcas
                this.itemsMV = r.buscador.modelos
                this.itemsAnoDesde = r.buscador.anos
                this.todosLosAnos = r.buscador.anos
                this.itemsMin = r.buscador.precios
                this.itemsMax = r.buscador.precios
                this.todosLosPrecios = r.buscador.precios
                this.destacados = r.destacados
                this.enVenta = r.enventa
                this.ultimos = r.ultimos
                this.mostrarPublicidad = true
                this.publicidad = r.publicidad

            })
        },   

        formatoPesosChilenos(valor){
             const formatterPeso = new Intl.NumberFormat('es-CL', {
                style: 'currency',
                currency: 'CLP',
                minimumFractionDigits: 0
            })

            return formatterPeso.format(valor)

        },
        
        abrirPublicacion(id){
            console.log("id")
            this.$router.push({path:'automotriz/publicacion/' + id})
        },
        buscar(value){
            this.encontrados = value.encontrados
            this.busquedaActiva = true
        }

       
         
        
    }
}
</script>

<style>

.card {
    min-width: 100%;
    min-height:80% ;
    max-width:90% ;
    max-height:100% ;
}
.titulo{
    font-size: 0.8em;
}
.precio {
    font-size: 0.5em;
}
.imagentarjeta{
   
    min-width: 100%;
    min-height:40% !important;
    max-width:100% ;
    max-height:40% !important;

}



.parrafos{
    background-color: #212529;
    
}

.parrafos h4, p {
    color: #ffff  !important;
}
.parrafos  p {
    font-size: 1em!important;
    line-height: normal!important;
    text-align: justify;
    padding: 0;
}
.v-input input, label {
    font-size: 0.7em!important;
}

.carousel__button span {
    font-size: 3em;
    background-color: #ffff;
}

</style>