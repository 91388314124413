<template>
<v-dialog v-model="show">
     <div class="text-center">
        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
            Términos y Condiciones del Servicio
            </v-card-title>

            <v-card-text>
           
           
            
            Acceder y utilizar los sitios web, PrecioConversable.com y PrecioConversable.cl en adelante PrecioConversable (Portales de Internet o sitios web) son acciones reguladas y normadas por la legislación vigente en Chile y/o en su país de origen y por los términos y condiciones contenidos en este texto que el usuario visitante declara conocer y aceptar en su totalidad.
            <br>
            <h5>Aceptación</h5>  
            Para la utilización de portales PrecioConversable el usuario registrado debe aceptar los términos y condiciones que se describen a continuación. La acción por parte del usuario de registrarse significará que conoce y acepta los términos.
            <br>
            <h5>Registro</h5> 
            El registro lo realiza el propio usuario mediante el ingreso de sus datos en el formulario de “Registro”
            El registro se completa con el envío de la información, previa confirmación realizada por el propio usuario.

            PrecioConversable puede modificar completa o parcialmente los Términos y Condiciones del Servicio, cambios que son informados en el mismo sitio web.
            <br>
            <h5>El usuario al registrarse en PrecioConversable declara que acepta:</h5> 
            Someterse a las leyes chilenas e internacionales que regulan la transmisión de datos obtenidos del servicio.
            Y Autoriza de acuerdo como se define en este texto el tratamiento y publicación por parte de PrecioConversable de los datos proporcionados, al realizar el registro en este sitio web y en los sistemas o bases de datos PrecioConversable.
            No utilizar su registro y/o publicaciones para comunicar situaciones o acciones ilegales.
            No interferir, interrumpir, alterar o modificar los dispositivos y medios tecnológicos hardware o software que componen el servicio.
            Utilizar el servicio para su uso personal no para reventa, transferencia o disposición para el uso o beneficio de cualquier otra persona o entidad.
            Reconocer que el servicio ha sido desarrollado, compilado, y conformado por PrecioConversable.
            No copiar, reproducir, recompilar, descompilar, realizar ingeniería inversa, distribuir, publicar, mostrar, ejecutar, modificar en la medida permitida por la legislación chilena e internacional vigente.
            No tener derechos o permisos en relación con el servicio, excepto el derecho a utilizar el servicio de acuerdo con las condiciones de prestación del servicio.
            Que PrecioConversable se reserva el derecho a modificar el contenido de todas o algunas de las especificaciones técnicas del servicio y acepta que los cambios puedan afectar su acceso al servicio.

            *Para todos los efectos legales derivados de este acuerdo de servicio las partes fijan domicilio en la ciudad de Viña del Mar de Santiago de Chile y se someten a la competencia de sus Tribunales de Justicia.
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
               
                @click="show = false"
            >
                Cerrar
            </v-btn>
            </v-card-actions>
        </v-card>
       
    </div>
</v-dialog>
   
</template>

<script>
export default {
    props: {
     value: Boolean
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    }
  }
}
</script>
<style>

</style>